<template>
    <Modal class="custom-modal">
        <ModalHeader class="custom-header">
            <h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal" style="position: relative;">
                <div class="form-group row">
                    <label class="col-3">
                        <div
                            style="width: 99px;height: 99px;margin-bottom: 2px;"
                        >
                            <img
                                style="border-radius: 50%;width: 100%;height: 100%;object-fit: cover;"
                                :src="doctorData.imageB64"
                                alt=""
                                @error="replaceByDefault"
                            />
                        </div>
                    </label>
                    <div v-if="!isViewOnly" class="col-9">
                        <FileInput
                            :onChange="onChangeImage"
                            :fileName="doctorData.imageName"
                            @delete-image="deleteImage"
                        />
                    </div>
                    <div v-else>
                        <div
                            style="display: flex;flex-direction: column;justify-content: end;margin-bottom: 8px;margin-left: 22px;width: 100%;"
                        >
                            <div
                                style="display:flex; justify-content: space-between;"
                            >
                                <label class="image-name" for="doctor-foto">{{
                                    doctorData.imageName
                                        ? doctorData.imageName
                                        : "No Image"
                                }}</label>
                            </div>
                            <p>JPG, GIF, or PNG. Max size of 800KB</p>
                            <div
                                style="flex-direction: unset;margin-left:unset;"
                                class="hapus-anchor"
                            >
                            <!-- :download="doctorData.imageName" :href="doctorData.imageB64" -->
                                <a @click="openImage">
                                    Lihat
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div
                    v-if="isViewOnly || modalTitle == 'Edit Dokter'"
                    class="form-group"
                >
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <span style="margin-left: 12px;">{{
                            doctorData.id | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group ">
                    <label class="label-form-doctor"
                        >Nama<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly" class="">
                        <input
                            type="text"
                            class="form-control ket-text"
                            v-model="doctorData.nama"
                            placeholder="Nama Dokter"
                            v-validate="'required'"
                            data-vv-as="Nama Dokter"
                            name="Dokter"
                            :class="{ 'is-invalid': errors.has('Dokter') }"
                        />
                        <div
                            v-show="errors.first('Dokter')"
                            class="invalid-feedback invalid-error"
                        >
                            {{ errors.first("Dokter") }}
                        </div>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            doctorData.nama | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group row form-modal">
                    <div
                        class="form-group col-6"
                        style="border-right: 1px solid #CFDFF5;"
                    >
                        <label class=" label-form-doctor">Tanggal Lahir</label>
                        <div v-if="!isViewOnly" class="">
                            <date-picker
                                class="custom-datepicker"
                                :changedValue.sync="doctorData.tglLahir"
                                :id-date-picker="'DOB_doctor'"
                                :picker-value="doctorData.tglLahir"
                                place-holder="Tanggal Lahir"
                                :is-validate="false"
                                end-date="0d"
                            ></date-picker>
                        </div>
                        <div v-else>
                            <span class="ket-text" style="margin-left: 12px;">{{
                                doctorData.tglLahir | dateFormat
                            }}</span>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class=" label-form-doctor"
                            >Gender<span style="color:red;">*</span></label
                        >
                        <div v-if="!isViewOnly" class="">
                            <multiselect
                                :searchable="false"
                                class="holiday-multiselect"
                                :allow-empty="true"
                                :multiple="false"
                                :close-on-select="true"
                                v-model="doctorData.gender"
                                :options="genderOptions"
                                placeholder="Pilih Gender"
                                :loading="genderOptions.length == 0"
                                :class="{
                                    'input-invalid': errorInput.gender
                                }"
                            ></multiselect>
                            <div
                                v-show="errorInput.gender"
                                class="invalid-error"
                            >
                                Gender harus diisi
                            </div>
                        </div>
                        <div v-else>
                            <span class="ket-text" style="margin-left: 12px;">{{
                                doctorData.gender | strip
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group ">
                    <label class="label-form-doctor">Klinik</label>
                    <div v-if="!isViewOnly" class="">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="doctorData.klinik"
                            :options="klinikOptions"
                            placeholder="Pilih Klinik"
                            :searchable="true"
                            :loading="isKlinikSelectLoading"
                            @search-change="searchKlinik"
                            :custom-label="customKlinikLabel"
                            ><template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                    style="background: #fafafa;"
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                    >{{ values.length }} Klinik Terpilih</span
                                ></template
                            >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Klinik
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Klinik Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                        <div style="margin-top: 15px;">
                            <div
                                v-for="(sub, index) in doctorData.klinik"
                                :key="index"
                                class="selected-multi"
                            >
                                <SelectedText
                                    :numberIndex="index"
                                    :nama="`${sub.nama} - ${sub.type}`"
                                >
                                </SelectedText>
                                <!-- <div class="text-multi">
                                    <p
                                        class="text-multi__number"
                                        style="width:10px;"
                                    >
                                        {{ index + 1 }}.
                                    </p>
                                    &nbsp;
                                    <p>{{ sub.nama }}</p>
                                </div> -->
                                <div class="button-multi">
                                    <TrashButton
                                        style="margin-left:24px;"
                                        @delete-emit="
                                            deleteSelected({
                                                index: index,
                                                propertyName: 'klinik'
                                            })
                                        "
                                    >
                                    </TrashButton>
                                    <!-- <button
                                        style="margin-left:24px;"
                                        @click.prevent="
                                            deleteSelected(index, 'klinik')
                                        "
                                        class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                        title="Hapus"
                                    >
                                        <TrashIcon :strokeColor="'#000000'" />
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            v-for="(sub, index) in doctorData.klinik"
                            :key="index"
                            class="selected-multi"
                        >
                            <SelectedText
                                :numberIndex="index"
                                :nama="`${sub.nama} - ${sub.type}`"
                            >
                            </SelectedText>
                        </div>
                        <div v-if="doctorData.klinik.length == 0">
                            <span class="ket-text" style="margin-left: 12px;"
                                >-</span
                            >
                        </div>
                    </div>
                </div>
                <div class="form-group ">
                    <label class=" label-form-doctor"
                        >Spesialisasi<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly" class="">
                        <multiselect
                            class="holiday-multiselect"
                            :class="{
                                'input-invalid': errorInput.spesialisasi
                            }"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="doctorData.spesialisasi"
                            :options="spesialisasiOptions"
                            placeholder="Pilih spesialisasi"
                            :searchable="true"
                            :loading="isSpesialisasiSelectLoading"
                            @search-change="searchSpesialisasi"
                        >
                            <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                    style="background: #fafafa;"
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                    >{{ values.length }} Spesialisasi
                                    Terpilih</span
                                ></template
                            >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Spesialisasi
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Spesialisasi Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                        <div
                            v-show="errorInput.spesialisasi"
                            class="invalid-error "
                        >
                            Spesialisasi harus diisi
                        </div>
                        <div style="margin-top: 15px;">
                            <div
                                v-if="doctorData.spesialisasi.length"
                                style="display:flex; justify-content:flex-end;"
                            >
                                <p style="width:47px;margin-right: 10px;">
                                    Default<span style="color:red;">*</span>
                                </p>
                                <p style="width:47px;margin-right: 10px;">
                                    Search
                                </p>
                                <p style="width:47px;">Hapus</p>
                            </div>
                            <div
                                v-for="(sub, index) in doctorData.spesialisasi"
                                :key="index"
                                class="selected-multi selected-spesialisai__container"
                            >
                                <SpesialisasiSub
                                    :spesialisasi="sub"
                                    :numberIndex="index"
                                    :canSelect="sub.canSelect"
                                    @delete-spesialisasi="deleteSelected"
                                    @select-default="selectSelected"
                                    :defaultChange.sync="sub.isDefault"
                                    :subspesialisasi.sync="sub.subSpesialisasi"
                                    :errorSub.sync="
                                        errorInput.subSpesialisasiDefault
                                    "
                                    :searchSubMethod="
                                        componentData.searchSubSpesialisasi
                                    "
                                >
                                </SpesialisasiSub>
                                <!-- <div class="text-multi">
                                    <p
                                        class="text-multi__number"
                                        style="width:10px;"
                                    >
                                        {{ index + 1 }}.
                                    </p>
                                    &nbsp;
                                    <p>{{ sub.nama }}</p>
                                </div>
                                <div class="button-multi d-flex">
                                    <div
                                        class="radio-check"
                                        style="width:47px;display: flex;justify-content: center;margin-right: 10px;padding-bottom: 10px;"
                                    >
                                        <input
                                            v-model="sub.isDefault"
                                            type="checkbox"
                                            name=""
                                            id=""
                                            :disabled="sub.canSelect"
                                            style="accent-color: #23415B;"
                                        />
                                    </div>
                                    <div
                                        style="width:47px;display: flex;justify-content: center;margin-right: 10px;"
                                    >
                                        <button
                                            @click.prevent="
                                                selectSelected(
                                                    index,
                                                    'spesialisasi'
                                                )
                                            "
                                            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                            :style="
                                                doctorData.spesialisasi[index]
                                                    .isPublish
                                                    ? 'background:#EBF6F4 !important;'
                                                    : 'background:#FFF2F2 !important;'
                                            "
                                            title="Hapus"
                                        >
                                            <EyeFillIcon
                                                style="width:16px;"
                                                v-if="
                                                    doctorData.spesialisasi[
                                                        index
                                                    ].isPublish
                                                "
                                            />
                                            <EyeIconSlashed
                                                style="width:16px;"
                                                v-else
                                            />
                                        </button>
                                    </div>
                                    <div
                                        style="width:47px;display: flex;justify-content: center;"
                                    >
                                        <button
                                            @click.prevent="
                                                deleteSelected(
                                                    index,
                                                    'spesialisasi'
                                                )
                                            "
                                            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                            title="Hapus"
                                        >
                                            <TrashIcon
                                                :strokeColor="'#000000'"
                                            />
                                        </button>
                                    </div>
                                </div> -->
                            </div>
                            <div
                                v-show="errorInput.spesialisasiDefault"
                                class="invalid-error "
                            >
                                Default Spesialisasi harus diisi
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            v-for="(sub, index) in doctorData.spesialisasi"
                            :key="index"
                            class="selected-multi"
                        >
                            <div class="text-multi">
                                <button
                                    @click.prevent=""
                                    class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                    :style="
                                        sub.isPublish
                                            ? 'background:#EBF6F4 !important;'
                                            : 'background:#FFF2F2 !important;'
                                    "
                                >
                                    <EyeFillIcon
                                        style="width:18px;"
                                        v-if="sub.isPublish"
                                    />
                                    <EyeIconSlashed
                                        style="width:18px;"
                                        v-else
                                    />
                                </button>
                                <p
                                    class="text-multi__number"
                                    style="width:10px;margin-left:10px"
                                >
                                    {{ index + 1 }}.
                                </p>
                                &nbsp;
                                <p>{{ sub.nama | strip }}</p>
                                &nbsp;
                                <span
                                    style="font-weight: 700;background: #E9F2FF;border-radius: 12px;height: 24px;padding: 0px 12px;"
                                    v-if="sub.isDefault"
                                    >Default
                                </span>
                            </div>
                        </div>
                        <div v-if="doctorData.spesialisasi.length == 0">
                            <span class="ket-text" style="margin-left: 12px;"
                                >-</span
                            >
                        </div>
                    </div>
                </div>
                <div v-if="isViewOnly" class="form-group ">
                    <label class=" label-form-doctor">Subspesialisasi</label>
                    <div>
                        <div
                            v-for="(sub, index) in doctorData.subSpesialisasi"
                            :key="index"
                            class="selected-multi"
                        >
                            <div class="text-multi">
                                <button
                                    @click.prevent=""
                                    class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                    :style="
                                        sub.isPublish
                                            ? 'background:#EBF6F4 !important;'
                                            : 'background:#FFF2F2 !important;'
                                    "
                                    title="Publish"
                                >
                                    <EyeFillIcon
                                        style="width:18px;"
                                        v-if="sub.isPublish"
                                    />
                                    <EyeIconSlashed
                                        style="width:18px;"
                                        v-else
                                    />
                                </button>
                                <p
                                    class="text-multi__number"
                                    style="width:10px;margin-left:10px;"
                                >
                                    {{ index + 1 }}.
                                </p>
                                &nbsp;
                                <p>{{ sub.nama | strip }}</p>
                                &nbsp;
                                <span
                                    style="font-weight: 700;background: #E9F2FF;border-radius: 12px;height: 24px;padding: 0px 12px;"
                                    v-if="sub.isDefault"
                                    >Default
                                </span>
                            </div>
                        </div>
                        <div v-if="doctorData.subSpesialisasi.length == 0">
                            <span class="ket-text" style="margin-left: 12px;"
                                >-</span
                            >
                        </div>
                    </div>
                </div>
                <div class="form-group ">
                    <label class=" label-form-doctor">Tipe Booking</label>
                    <div
                        v-if="!isViewOnly"
                        style="margin-top: 12px;"
                        class=" tipe-booking-doctor"
                    >
                        <div>
                            <MobileWhite
                                v-if="!doctorData.tipeBooking"
                                style="width: 20px;"
                            />
                            <Mobile style="width: 20px;" v-else />
                            <span class="ket-text">via Care Dokter</span>
                        </div>
                        <label class="switch-publish">
                            <input
                                v-model="doctorData.tipeBooking"
                                type="checkbox"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div v-else style="margin-top: 12px;" class="d-flex">
                        <div
                            v-if="doctorData.tipeBooking"
                            style="margin-right:20px"
                        >
                            <Mobile style="width: 20px;" />
                            <span class="ket-text">via Care Dokter</span>
                        </div>
                        <div>
                            <CallFill style="width: 20px;" />
                            <span class="ket-text">via Contact Center</span>
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group ">
                    <label class=" label-form-doctor"
                        >Tipe Konsultasi<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly" class="">
                        <multiselect
                            class="holiday-multiselect"
                            :class="{
                                'input-invalid': errorInput.tipeKonsultasi
                            }"
                            :allow-empty="true"
                            :multiple="false"
                            :close-on-select="true"
                            v-model="doctorData.tipeKonsultasi"
                            :options="tipeKonsultasiOptions"
                            placeholder="Pilih Tipe Konsultasi"
                            :loading="tipeKonsultasiOptions.length == 0"
                        ></multiselect>
                        <div
                            v-show="errorInput.tipeKonsultasi"
                            class="invalid-error "
                        >
                            Tipe Konsultasi harus diisi
                        </div>
                    </div>
                    <div v-else>
                        <span class="ket-text" style="margin-left: 12px;">{{
                            doctorData.tipeKonsultasi | strip
                        }}</span>
                    </div>
                </div>
                <div
                    v-if="doctorData.tipeKonsultasi == 'Semua Tipe Konsultasi'"
                    class="form-group "
                >
                    <label class=" label-form-doctor"
                        >Tarif Telekonsultasi<span style="color:red;"
                            >*</span
                        ></label
                    >
                    <div v-if="!isViewOnly" class="">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="false"
                            :close-on-select="true"
                            v-model="doctorData.tarifTelekonsultasi"
                            :options="tarifTelekonsultasiOptions"
                            placeholder="Pilih Tarif Telekonsultasi"
                            :loading="isTarifTelekonsultasiSelectLoading"
                            :class="{
                                'input-invalid': errorInput.tarifTelekonsultasi
                            }"
                            @search-change="searchTarifKonsultasi"
                            :custom-label="tarifLabel"
                        >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Tarif Telekonsultasi
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Tarif Telekonsultasi Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                        <div
                            v-show="errorInput.tarifTelekonsultasi"
                            class="invalid-error "
                        >
                            Tarif Telekonsultasi harus diisi
                        </div>
                    </div>
                    <div v-else>
                        <span class="ket-text" style="margin-left: 12px;"
                            >{{ doctorData.tarifTelekonsultasi.nama | strip }} -
                            {{
                                doctorData.tarifTelekonsultasi.jumlah
                                    | moneyFormat
                            }}</span
                        >
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group">
                    <label class=" label-form-doctor">Text To Speech</label>
                    <div class="row form-modal">
                        <div v-if="!isViewOnly" class="col-11">
                            <!-- ket-text -->
                            <input
                                type="text"
                                class="form-control ket-text"
                                v-model="doctorData.textToSpeech"
                                placeholder="Text To Speech"
                                name="textToSpeech"
                            />
                            <!-- <VueInputText
                                v-model="doctorData.textToSpeech"
                                :placeholder="'Text To Speech'"
                                :name="'text-to-speech'"
                            /> -->
                        </div>
                        <div v-else class="col-10">
                            <span class="ket-text" style="margin-left: 12px;">{{
                                doctorData.textToSpeech | strip
                            }}</span>
                        </div>
                        <div class="col-1">
                            <button
                                :disabled="
                                    speechState || !doctorData.textToSpeech
                                "
                                type="button"
                                title="Bunyikan"
                                class="btn btn-secondary btn-icon"
                                @click="speakTts"
                            >
                                <!-- <i :class="'la la-play'"></i> -->
                                <i
                                    :class="[
                                        speechState
                                            ? 'flaticon2-speaker'
                                            : 'la la-play'
                                    ]"
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </ModalBody>
        <ModalFooter class="custom-footer footer-doctor">
            <div v-if="!isViewOnly">
                <span>
                    <EyeFillIcon
                        style="width: 18px;"
                        v-if="doctorData.isPublishProfile"
                    />
                    <EyeIconSlashed style="width: 18px;" v-else />Publish Dokter
                </span>
                <label style="margin-top: 7px;" class="switch-publish">
                    <input
                        v-model="doctorData.isPublishProfile"
                        type="checkbox"
                    />
                    <span class="slider round"></span>
                </label>
            </div>
            <div v-else>
                <PublishChips
                    :isPublished="doctorData.isPublishProfile"
                    :textString="
                        doctorData.isPublishProfile
                            ? 'published'
                            : 'unpublished'
                    "
                />
            </div>
            <div v-if="!isViewOnly" class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    @click.prevent="simpanData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
            <div v-else>
                <button v-if="permissionCheck('isCanEditDoctor')"
                    @click.prevent="editModal"
                    class="btn btn-primary custom-modal-button"
                >
                    Edit
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import Speech from "speak-tts";
const speech = new Speech();

import FileInput from "../../../_base/FileInputCustom.vue";

// import PlaceHolderImage from "../../assets/placeholder.png";

// import JadwalTime from "../../../_select/JadwalTime.vue";
// import VueInputNumber from "../../../_general/VueInputNumber.vue";
import DatePicker from "../../../_general/DatePicker.vue";
import vueMultiSelect from "../../../_select/VueMultiSelect";
import Multiselect from "vue-multiselect";

import PublishChips from "../../../_base/PublishChips.vue";
import TrashButton from "../../../_base/TrashButton.vue";
import SelectedText from "../../../_base/SelectedText.vue";

import VueInputText from "../../../_general/VueInputText.vue";
import SpesialisasiSub from "../SpesialisasiSub.vue";

import MobileWhite from "../../../_icons/MobileWhite.vue";
import Mobile from "../../../_icons/Mobile.vue";
import EyeIcon from "../../../_icons/EyeIcon.vue";
import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";
import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
import TrashIcon from "../../../_icons/TrashIcon.vue";
import CallFill from "../../../_icons/CallFill.vue";
// import VueInputTextArea from "../../../_general/VueTextArea.vue";
// import VueInputEmail from "../../../_general/VueInputEmail.vue";
// import VueDatePicker from "../../../_general/VueDatePicker.vue";

import VeeValidate from "vee-validate";
VeeValidate.setMode("passive");

export default {
    name: "AddDoctorModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        VueInputText,
        Multiselect,
        DatePicker,
        MobileWhite,
        Mobile,
        EyeIcon,
        EyeFillIcon,
        EyeIconSlashed,
        FileInput,
        TrashIcon,
        CallFill,
        PublishChips,
        SpesialisasiSub,
        vueMultiSelect,
        TrashButton,
        SelectedText
    },
    mixins: [ModalMixin],
    provide() {
        return {
            $validator: this.$validator
        };
    },
    watch: {
        "doctorData.gender": {
            handler: function(val, oldVal) {
                if (!val) {
                    this.errorInput.gender = true;
                } else {
                    this.errorInput.gender = false;
                }
            },
            deep: true
        },
        "doctorData.spesialisasi": {
            handler: function(val, oldVal) {
                if (!oldVal.length && val.length == 1) {
                    val[0].isDefault = true;
                }
                let isDefaultExist = false;
                val.forEach(spe => {
                    if (isDefaultExist) {
                        spe.isDefault = false;
                    }
                    if (spe.isDefault) {
                        isDefaultExist = true;
                    }
                });
                val.forEach(spe => {
                    if (isDefaultExist) {
                        if (!spe.isDefault) {
                            spe.canSelect = true;
                        } else {
                            spe.canSelect = false;
                        }
                    } else {
                        spe.canSelect = false;
                    }
                });
                this.errorInput.spesialisasiDefault = !isDefaultExist;
                if (!val.length) {
                    this.errorInput.spesialisasiDefault = false;
                    this.errorInput.spesialisasi = true;
                } else {
                    this.errorInput.spesialisasi = false;
                }
            },
            deep: true
        },
        "doctorData.tipeKonsultasi": {
            handler: function(val, oldVal) {
                // console.log(val, "tipeKonsultasi");
                if (val == "Hanya Konsultasi Langsung") {
                    this.doctorData.tarifTelekonsultasi = null;
                }
                if (!val) {
                    this.doctorData.tarifTelekonsultasi = null;
                    this.errorInput.tipeKonsultasi = true;
                } else {
                    this.errorInput.tipeKonsultasi = false;
                }
            },
            deep: true
        },
        "doctorData.tarifTelekonsultasi": {
            handler: function(val, oldVal) {
                if (this.doctorData.tipeKonsultasi == "Semua Tipe Konsultasi") {
                    if (!val) {
                        this.errorInput.tarifTelekonsultasi = true;
                    } else {
                        this.errorInput.tarifTelekonsultasi = false;
                    }
                }
            },
            deep: true
        }
    },
    filters: {
        strip(text) {
            return text ? text : " - ";
        },
        moneyFormat(money) {
            if (money && typeof money == "number") {
                return `Rp. ${money.toLocaleString("id-ID")}`;
            }
        },
        dateFormat(date){
            let dateStr = `-`
            if (date) {
                dateStr = moment(date).format(`DD/MMM/YYYY`)
            }
            return dateStr
        }
    },
    data() {
        var vx = this;
        return {
            modalTitle: "Tambah Dokter",
            baseUrl: process.env.VUE_APP_API_URL,
            endpoint: "/gallery/api/image/alt/",
            // imagePlaceHolder: PlaceHolderImage,
            klinikOptions: [],
            spesialisasiOptions: [],
            subSpesialisasiOptions: [],
            tipeKonsultasiOptions: [
                "Semua Tipe Konsultasi",
                "Hanya Konsultasi Langsung"
            ],
            tarifTelekonsultasiOptions: [],
            genderOptions: ["MALE", "FEMALE"],
            doctorData: {
                id: null,
                imageB64: "",
                imageName: null,
                imageInfo: {
                    id: null,
                    imageContentType: null,
                    width: null,
                    height: null,
                    taken: null,
                    uploaded: null,
                    checksum: null
                },
                nama: null,
                tglLahir: null,
                gender: null,
                klinik: [],
                spesialisasi: [],
                subSpesialisasi: [],
                tipeBooking: true,
                tipeKonsultasi: null,
                tarifTelekonsultasi: null,
                textToSpeech: null,
                isPublishProfile: true
            },
            speechState: false,
            isViewOnly: false,
            errorInput: {
                gender: false,
                spesialisasi: false,
                tipeKonsultasi: false,
                tarifTelekonsultasi: false,
                spesialisasiDefault: false,
                subSpesialisasiDefault: false
            },
            isSubspesialisasiSelectLoading: false,
            isSpesialisasiSelectLoading: false,
            isKlinikSelectLoading: false,
            isTarifTelekonsultasiSelectLoading: false,
            searchKlinik: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchKlinik ==
                                "function"
                            ) {
                                vx.isKlinikSelectLoading = true;
                                const resp = await vx.componentData.searchKlinik(
                                    query
                                );
                                vx.isKlinikSelectLoading = false;
                                vx.klinikOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchKlinik ==
                                "function"
                            ) {
                                vx.isKlinikSelectLoading = true;
                                const resp = await vx.componentData.searchKlinik();
                                vx.isKlinikSelectLoading = false;
                                vx.klinikOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isKlinikSelectLoading = false;
                    console.log(error);
                }
            }, 1000),
            searchSpesialisasi: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSpesialisasi ==
                                "function"
                            ) {
                                vx.isSpesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSpesialisasi(
                                    query
                                );
                                vx.isSpesialisasiSelectLoading = false;
                                vx.spesialisasiOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSpesialisasi ==
                                "function"
                            ) {
                                vx.isSpesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSpesialisasi();
                                vx.isSpesialisasiSelectLoading = false;
                                vx.spesialisasiOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isSpesialisasiSelectLoading = false;
                    console.log(error);
                }
            }, 1000),
            searchSubSpesialisasi: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSubSpesialisasi ==
                                "function"
                            ) {
                                vx.isSubspesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSubSpesialisasi(
                                    query
                                );
                                vx.isSubspesialisasiSelectLoading = false;
                                vx.subSpesialisasiOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSubSpesialisasi ==
                                "function"
                            ) {
                                vx.isSubspesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSubSpesialisasi();
                                vx.isSubspesialisasiSelectLoading = false;
                                vx.subSpesialisasiOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isSubspesialisasiSelectLoading = false;
                    console.log(error);
                }
            }, 1000),
            searchTarifKonsultasi: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchTarifKonsultasi ==
                                "function"
                            ) {
                                vx.isTarifTelekonsultasiSelectLoading = true;
                                const resp = await vx.componentData.searchTarifKonsultasi(
                                    query
                                );
                                vx.isTarifTelekonsultasiSelectLoading = false;
                                vx.tarifTelekonsultasiOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchTarifKonsultasi ==
                                "function"
                            ) {
                                vx.isTarifTelekonsultasiSelectLoading = true;
                                const resp = await vx.componentData.searchTarifKonsultasi();
                                vx.isTarifTelekonsultasiSelectLoading = false;
                                vx.tarifTelekonsultasiOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isTarifTelekonsultasiSelectLoading = false;
                    console.log(error);
                }
            }, 1000)
        };
    },
    methods: {
        debugBase64(base64URL){
            var win = window.open();
            win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;background: black;" allowfullscreen></iframe>');
        },
        openImage() {
            this.debugBase64(this.doctorData.imageB64);
        },
        replaceByDefault(e) {
            try {
                e.target.src = require("../../assets/placeholder-doctor.png");
            } catch (error) {
                console.log(error, "error require image placeholder");
            }
        },
        getPlaceHolder: function() {
            return require("../../assets/placeholder-doctor.png");
        },
        tarifLabel({ nama, jumlah }) {
            return `${nama} - ${`Rp. ${jumlah.toLocaleString("id-ID")}`}`;
        },
        selectSelected({ index, propertyName }) {
            this.doctorData[propertyName][index].isPublish = !this.doctorData[
                propertyName
            ][index].isPublish;
        },
        deleteSelected({ index, propertyName }) {
            this.doctorData[propertyName].splice(index, 1);
        },
        onChangeImage(e) {
            if (e.target.files[0].size > 819200) {
                toastr.error("File size is too big");
            } else {
                this.doctorData.imageInfo.taken = moment(
                    e.target.files[0].lastModified
                );
                this.doctorData.imageInfo.uploaded = moment();
                // this.doctorData.imageInfo.uploaded = moment().format(
                //     `YYYY-MM-DDThh:mm:ssZ`
                // );
                this.doctorData.imageInfo.imageContentType =
                    e.target.files[0].type;
                this.doctorData.imageName = e.target.files[0].name;
                this.convertFileToB64(e.target.files[0], result => {
                    this.doctorData.imageB64 = result;
                });
            }
        },
        convertFileToB64(file, cb) {
            var vx = this;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(e) {
                const image = new Image();
                image.src = e.target.result;
                image.onload = e => {
                    const height = e.target.height;
                    const width = e.target.width;
                    vx.doctorData.imageInfo.height = height;
                    vx.doctorData.imageInfo.width = width;
                };
                cb(reader.result);
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
            };
        },
        deleteImage() {
            this.doctorData.imageName = null;
            this.doctorData.imageB64 = "";
        },
        simpanData() {
            this.$validator.validateAll().then(result => {
                // console.log(result, 247, "result");
                // if (!this.klinikData.klinikType) {
                //     this.isTypeKlinikError = true;
                // }
                if (!this.doctorData.gender) {
                    this.errorInput.gender = true;
                }
                if (this.doctorData.spesialisasi.length == 0) {
                    this.errorInput.spesialisasi = true;
                } else {
                    let isDefaultExist = false;
                    this.doctorData.spesialisasi.forEach(spe => {
                        if (spe.isDefault) {
                            isDefaultExist = true;
                        }
                    });
                    this.errorInput.spesialisasiDefault = !isDefaultExist;
                }
                if (!this.doctorData.tipeKonsultasi) {
                    this.errorInput.tipeKonsultasi = true;
                } else if (
                    this.doctorData.tipeKonsultasi == "Semua Tipe Konsultasi"
                ) {
                    if (!this.doctorData.tarifTelekonsultasi) {
                        this.errorInput.tarifTelekonsultasi = true;
                    }
                }
                let isError = false;
                Object.keys(this.errorInput).forEach(key => {
                    if (this.errorInput[key]) {
                        isError = true;
                    }
                });
                if (result && !isError) {
                    if (this.componentData) {
                        if (typeof this.componentData.action == "function") {
                            this.componentData.action(this.doctorData);
                        }
                    }
                }
            });
        },
        subMapper(speId, subData) {
            let subSpe = [];
            if (Array.isArray(subData)) {
                subData.forEach(sub => {
                    if (sub.subSpesialisasi) {
                        if (sub.subSpesialisasi.spesialisasi) {
                            if (speId == sub.subSpesialisasi.spesialisasi.ID) {
                                subSpe.push({
                                    id: sub.subSpesialisasi.ID,
                                    nama: sub.subSpesialisasi.namaIndo,
                                    isDefault: sub.isDefault,
                                    isPublish: sub.isPublish,
                                    canSelect: false
                                });
                            }
                        }
                    }
                });
            }
            return subSpe;
        },
        dataMapper(data, propertyName, subData) {
            return data.map(item => {
                let payload = {
                    id: item[propertyName].ID,
                    nama: item[propertyName].namaIndo
                };
                if (propertyName != "klinik") {
                    payload.isDefault = item.isDefault ? item.isDefault : false;
                    payload.isPublish = item.isPublish ? item.isPublish : false;
                    payload.canSelect = item.isDefault ? false : true;
                    if (propertyName == "spesialisasi" && item[propertyName]) {
                        payload.subSpesialisasi = this.subMapper(
                            item[propertyName].ID,
                            subData
                        );
                    }
                } else {
                    payload.type = item[propertyName].type;
                }
                return payload;
            });
        },
        async speakTts() {
            try {
                const textTs = this.doctorData.textToSpeech;
                speech.init({
                    volume: 1,
                    lang: "id-ID",
                    rate: 0.5,
                    pitch: 2,
                    voice: "Google Bahasa Indonesia",
                    splitSentences: true,
                    listeners: {
                        onvoiceschanged: voices => {
                            console.log("Event voiceschanged", voices);
                        }
                    }
                });
                await speech.init();
                await speech.speak({
                    text: "Antrian:" + textTs + ", 0 0 3, ke, counter 1",
                    listeners: {
                        onstart: () => {
                            this.speechState = true;
                        },
                        onend: () => {
                            this.speechState = false;
                        },
                        onresume: () => {
                            this.speechState = true;
                        }
                    }
                });
            } catch (error) {
                console.log(error, "speakTts Error");
            }
        },
        editModal() {
            this.isViewOnly = false;
            this.modalTitle = "Edit Dokter";
        },
        initInitialData() {
            if (this.componentData) {
                if (this.componentData.dropdownData) {
                    this.klinikOptions = this.componentData.dropdownData.klinikOptions;
                    this.spesialisasiOptions = this.componentData.dropdownData.spesialisasiOptions;
                    this.subSpesialisasiOptions = this.componentData.dropdownData.subSpesialisasiOptions;
                    // this.tipeKonsultasiOptions = this.componentData.dropdownData.tipeKonsultasiOptions;
                    this.tarifTelekonsultasiOptions = this.componentData.dropdownData.tarifTelekonsultasiOptions;
                }
                if (this.componentData.isViewOnly) {
                    this.isViewOnly = this.componentData.isViewOnly;
                }
                if (this.componentData.modalTitle) {
                    this.modalTitle = this.componentData.modalTitle;
                }
                if (this.componentData.photo) {
                    // this.doctorData.imageInfo
                    this.doctorData.imageInfo.id = this.componentData.photo.id;
                    this.doctorData.imageInfo.imageContentType = this.componentData.photo.imageContentType;
                    this.doctorData.imageInfo.width = this.componentData.photo.width;
                    this.doctorData.imageInfo.height = this.componentData.photo.height;
                    this.doctorData.imageInfo.taken = this.componentData.photo.taken;
                    this.doctorData.imageInfo.uploaded = this.componentData.photo.uploaded;
                    this.doctorData.imageInfo.checksum = this.componentData.photo.checksum;
                    this.doctorData.imageName = this.componentData.photo.title;
                    let b64type =
                        `data:` +
                        this.componentData.photo.imageContentType +
                        `;base64,`;
                    this.doctorData.imageB64 =
                        b64type + this.componentData.photo.image;
                }
                if (this.componentData.initialData) {
                    let doctorDataKeys = {
                        ID: "id",
                        nama: "nama",
                        tanggalLahir: "tglLahir",
                        textToSpeech: "textToSpeech",
                        tarif: "tipeKonsultasi",
                        dokterKliniks: "klinik",
                        isBookAllow: "tipeBooking",
                        isPublish: "isPublishProfile",
                        jenisKelamin: "gender"
                    };
                    let mapperKeys = {
                        dokterKliniks: "klinik",
                        dokterSpesialisasis: "spesialisasi",
                        dokterSubSpesialisasis: "subSpesialisasi"
                    };

                    Object.keys(this.componentData.initialData).forEach(key => {
                        if (this.componentData.initialData[key]) {
                            if (
                                key != "dokterKliniks" &&
                                key != "dokterSpesialisasis" &&
                                key != "dokterSubSpesialisasis" &&
                                key != "tarif" &&
                                key != "jadwals"
                                // key !== "isBookAllow"
                            ) {
                                this.doctorData[
                                    doctorDataKeys[key]
                                ] = this.componentData.initialData[key];
                            } else if (key == "tarif") {
                                this.doctorData.tipeKonsultasi =
                                    "Semua Tipe Konsultasi";
                                this.doctorData.tarifTelekonsultasi = this.componentData.initialData[
                                    key
                                ];
                            } else if (key != "jadwals") {
                                this.doctorData[
                                    mapperKeys[key]
                                ] = this.dataMapper(
                                    this.componentData.initialData[key],
                                    mapperKeys[key],
                                    this.componentData.initialData
                                        .dokterSubSpesialisasis
                                );
                            }
                        }
                        if (key == "isPublish") {
                            this.doctorData[
                                doctorDataKeys[key]
                            ] = this.componentData.initialData[key];
                        }
                        if (key == "isBookAllow") {
                            this.doctorData[
                                doctorDataKeys[key]
                            ] = this.componentData.initialData[key];
                        }
                        if (key == "tarif") {
                            if (!this.componentData.initialData.tarif) {
                                this.doctorData.tipeKonsultasi =
                                    "Hanya Konsultasi Langsung";
                            }
                        }
                    });
                }
            }
        },
        customKlinikLabel({ nama, type }) {
            return `${nama} - ${type}`;
        },
        permissionCheck(permission){
            let permissionBool = true
            if (this.componentData) {
                if(this.componentData.permissionObject){
                    if (!this.componentData.permissionObject[permission]) {
                        permissionBool = this.componentData.permissionObject[permission]
                    }
                }
            }
            return permissionBool
        }
    },
    created() {
        this.initInitialData();
        if (!this.isViewOnly) {
            this.searchKlinik();
            this.searchSpesialisasi();
            // this.searchSubSpesialisasi();
            this.searchTarifKonsultasi();
        }
    }
};
</script>

<style></style>
