<template>
    <div class="show-not-found background-not-supported">
        <div class="container-flex">
            <img :src="logoCaredokter" />
            <img :src="errorLaptop" />
                <label class="font-forbidden">ERROR</label>
                <label class="font-access"
                    >Halaman tidak bisa dimuat</label
                >
                <button class="btn btn-primary-caredokter" @click="refreshPage">
                    Refresh
                </button>
        </div>
    </div>
</template>

<script>
import laptopError from "@/assets/img/laptop-error.png";
import logoCaredokter from "@/assets/img/caredokter.png";

export default {
    data() {
        return {
            errorLaptop: `${laptopError}`,
            logoCaredokter: `${logoCaredokter}`,
        };
    },

    methods: {
        refreshPage() {
            location.reload()
        }
           
    }
};
</script>
