// import { param } from "jquery";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import ReservationRepository from "../repositories/ReservationRepository";
const DoctorRepository = RepositoryFactory.get("doctor");
import mapperData from '../services/mapperData'

// const TujuanRujukanRepository = RepositoryFactory.get("tujuanRujukan");
const QueueDeviceRepository = RepositoryFactory.get("queueDevice");
// import INVALIDTYPE from "../constants/invalid-booking-list-enum";

// import createRequestOption from '../repositories/RequestParamUtil';

import Axios from "axios";

export default {
    namespaced: true,
    state: {
        date: moment().format("YYYY-MM-DD"),
        type: 2,
        calendarApi: null,
        listDokter: [],
        listPolis: [],
        loadingApi: false,
        dokter: null,
        dokterArray: [],
        poli: null,
        showModalHelp: false,
        stompClient: null,
        showModalReservasi: false,
        doctorLogin: false,
        listCallCenterBooking: [],
        selectedPanel: {},
        filterSearchParams: {},
        checkBoxParams: {},
        sortingParams: "",
        isLoadedBtn: false,
        totalPageCallCenter: 1,
        paginate: false,
        callCenterPage: 1,
        defaultParams: {},
        showDetailPasien: true,
        slotDefect: "multi",
        isPermissionWhatsapp: false,
        isMonthCallendar: false,
        isInvBooking: false,
        fetchInvBooking: false,
        invalidBooking: "All",
        loadSpinner: false,
        specialties: [],
        selectedSpecialties: null,
        AbortControllerAndToken: [],
        abortTokenSource: null
    },
    mutations: {
        SET_LOAD_SPINNER(state, res) {
            state.loadSpinner = res
        },
        SET_PAGINATE(state, res) {
            state.paginate = res;
        },
        SET_INVALID_BOOKING(state, res) {
            state.invalidBooking = res;
        },
        SET_IS_INV_BOOKING(state, res) {
            state.isInvBooking = res;
        },
        SET_FETCH_INV_BOOKING(state, res) {
            state.fetchInvBooking = res;
        },
        SET_IS_MONTH_CALENDAR(state, res) {
            state.isMonthCallendar = !state.isMonthCallendar;
        },
        SET_SLOT_DEFECT(state, res) {
            state.slotDefect = res;
        },
        SET_PERMISSION_WHATSAPP(state, res) {
            state.isPermissionWhatsapp = res;
        },
        SET_IS_LOADED_BTN(state, res) {
            state.isLoadedBtn = res;
        },
        SET_TOTAL_PAGE_CALL_CENTER(state, res) {
            state.totalPageCallCenter = res;
        },
        SET_DEFAULT_PARAMS(state, res) {
            state.defaultParams = res;
        },
        SET_FILTER_SEARCH_PARAMS(state, res) {
            state.filterSearchParams = res;
        },
        SET_SORTING_PARAMS(state, res) {
            state.sortingParams = res;
        },
        SET_CHECKBOX_PARAMS(state, res) {
            state.checkBoxParams = res;
        },
        SET_LIST_CALL_CENTER_BOOKING(state, res) {
            if (state.paginate) {
                for (const i in res) {
                    state.listCallCenterBooking.push(res[i]);
                }
            } else {
                state.callCenterPage = 1;
                state.totalPageCallCenter = 1;
                // state.listCallCenterBooking = []
                state.listCallCenterBooking = res;
            }
        },
        SET_SELECTED_PANEL(state, res) {
            state.selectedPanel = res;
        },
        SET_STOMP_CLIENT(state, res) {
            state.stompClient = res;
        },
        SET_DATE(state, res) {
            state.date = res;
        },
        SET_TYPE(state, res) {
            state.type = res;
        },
        SET_CALENDAR_API(state, res) {
            state.calendarApi = res;
        },
        SET_LIST_DOKTER(state, res) {
            state.listDokter = res;
        },
        SET_CONCAT_LIST_DOKTER(state,res){
            state.listDokter = state.listDokter.concat(res);
        },
        RESET_LIST_DOKTER(state){
            state.listDokter = []
        },
        SET_LIST_POLIS(state, res) {
            state.listPolis = res;
        },
        SET_LOADING_API(state, res) {
            state.loadingApi = res;
        },
        SET_DOKTER(state, res) {
            state.dokter = res;
        },
        SET_DOKTER_ARRAY(state , res) {
            state.dokterArray = res
        },
        SET_SPECIALTIES(state , res) {
            state.specialties = res
        },
        SET_POLI(state, res) {
            state.poli = res;
            state.dokter = null;
        },
        SET_SHOW_MODAL_HELP(state, res) {
            state.showModalHelp = res;
        },
        SET_SHOW_MODAL_RESERVASI(state, res) {
            state.showModalReservasi = res;
        },
        SET_DOCTOR_LOGIN(state, res) {
            state.doctorLogin = res;
        },
        SET_SHOW_PASIEN(state, res) {
            state.showDetailPasien = res;
        },
        SET_SELECTED_SPECIALTIES(state,res){
            state.selectedSpecialties = res
        },
        SET_ABORT_TOKEN(state, res){
            state.AbortControllerAndToken = res
        },
        SET_ABORT_SOURCE_TOKEN(state, res) {
            state.abortTokenSource = res
        }
    },
    actions: {
        GET_DATA_FROM_GENERATED_ID ({commit}, generatedId) {
            commit('SET_LOAD_SPINNER', true)
            ReservationRepository.getReservationAppointmentBackoffice(generatedId)
            .then( _resp => {
                commit('SET_LOAD_SPINNER', false)
                const dataPanel = mapperData.mapperInfoPanel(_resp.data)
                commit('SET_SELECTED_PANEL', dataPanel)
            })
        },
        SET_RECORD_PANEL_SELECTED({ state, commit }, data) {
            const dataAll = { ...state.selectedPanel, ...data };
            commit("SET_SELECTED_PANEL", dataAll);
        },
        SET_SELECTED_PANEL({ commit }, res) {
            commit("SET_SELECTED_PANEL", res);
        },
        GET_CALL_CENTER_BOOKING({ commit }, res) {
            commit("SET_LIST_CALL_CENTER_BOOKING", res.data);
        },
        SET_CHANGE_LIST({ state, commit }, res) {
            commit("SET_LIST_CALL_CENTER_BOOKING", res);
        },
        RESET_POLI_DOKTER({ commit, state, dispatch }, res) {
            commit("SET_POLI", res.tujuanRujukan);
            commit("SET_DOKTER", res);
        },
        SOCK_MSG({ commit, state, dispatch }, res) {
            if (state.stompClient && state.stompClient.connected) {
                const msg = {
                    page: res.page ? res.page : res,
                    sessionId: res.sessionId ? res.sessionId : state.dokter.id,
                    date: state.date
                };
                state.stompClient.send("/topic/webbo", JSON.stringify(msg), {});
            }
        },
        SETTING_TYPE({ commit, state, dispatch }, res) {
            commit("MonthCalendarStore/SET_EVENTS", [], { root: true });
            commit("DayCalendarStore/SET_EVENTS", [], { root: true });
            commit("SET_TYPE", res);
        },
        RELOAD_CALENDAR({ commit, state, dispatch }) {
            let dispatchStr = "MonthCalendarStore/GET_EVENT"
            if (state.type == 1) {
                dispatchStr = "MonthCalendarStore/GET_EVENT"
            } else if (state.type == 2) {
                dispatchStr = 'DayCalendarStore/GET_SLOT_EVENT'
                state.calendarApi.removeAllEvents();
            }
            return new Promise((resolve, reject) => {
                dispatch(dispatchStr, {resolve, reject}, { root: true })
            })
        },
        GET_DATE({ commit, state, dispatch }) {
            var date = moment(state.calendarApi.getDate()).format("YYYY-MM-DD");
            commit("SET_DATE", date);
        },
        TODAY({ commit, state, dispatch }) {
            state.calendarApi.today();
            dispatch("GET_DATE");
        },
        PREV({ commit, state, dispatch }) {
            state.calendarApi.prev();
            dispatch("GET_DATE");
        },
        NEXT({ commit, state, dispatch }) {
            state.calendarApi.next();
            dispatch("GET_DATE");
        },
        async GO_TO_DATE({ commit, state, dispatch }, res) {
            commit("SET_DATE", res);
            await state.calendarApi.gotoDate(res);
            dispatch("GET_DATE");
        },
        CHANGE_TYPE({ commit, state, dispatch }, res) {
            setTimeout(() => {
                var momentDate = moment(state.date);
                var toDate =
                    momentDate.month() == moment().month()
                        ? moment().format("YYYY-MM-DD")
                        : momentDate.startOf("month").format("YYYY-MM-DD");
                dispatch("GO_TO_DATE", toDate);
                dispatch("RELOAD_CALENDAR");
            }, 10);
        },
        closeConnection({state}) {
            state.abortTokenSource.cancel('cancel request.')
        },
        async GET_LIST_CALL_CENTER_BOOKING({ commit, state, dispatch }, res) {
            return new Promise((resolve, reject) => {
                if(state.abortTokenSource) {
                    state.abortTokenSource.cancel('cancel request.')
                    commit("SET_TOTAL_PAGE_CALL_CENTER", 0);
                }
                const cancelToken = Axios.CancelToken;
                const source = cancelToken.source()
                commit('SET_ABORT_SOURCE_TOKEN', source)

                const sortingParams = {
                    sort: state.sortingParams
                };
                // filter empty params
                var params = {
                    ...state.defaultParams,
                    ...state.filterSearchParams,
                    ...state.checkBoxParams,
                    ...sortingParams
                };
    
                const filteredParams = Object.fromEntries(
                    Object.entries(params).filter(
                        ([key, value]) => value.length > 0 || value > 0
                    )
                );
                filteredParams.size = 10;
    
                if (res) {
                    commit("SET_PAGINATE", true);
                    filteredParams.page = state.callCenterPage++;
                } else {
                    filteredParams.page = 0;
                    commit("SET_PAGINATE", false);
                    commit("SET_LOADING_API", true);
                }
                // console.log(filteredParams, "filteredParams");
                ReservationRepository.getReservasisDasboard(filteredParams, state.abortTokenSource.token)
                    .then(async (response) => {
                        let data = response.data.result;
                        data = await helpers.mapperDefectStatus(data);
                        if (state.slotDefect == "defect") {
                            data = data.filter(item => item.defect);
                        } else if (state.slotDefect == "notDefect") {
                            data = data.filter(item => !item.defect);
                        }
                        commit("SET_LIST_CALL_CENTER_BOOKING", data);
                        commit(
                            "SET_TOTAL_PAGE_CALL_CENTER",
                            parseInt(response.data.count)
                        );
                        if (
                            state.callCenterPage <
                                Math.ceil(state.totalPageCallCenter / 10)
                        ) {
                            dispatch("GET_LIST_CALL_CENTER_BOOKING", "plus");
                        }
                        resolve(true)
                    })
                    .catch(err => {
                        reject(true)
                        if(typeof err != 'string') {
                            toastr.error("error get data");
                        }
                    })
                    .finally(() => {
                        commit("SET_LOADING_API", false);
                    });

            })
        },
        async GET_DATA_FROM_API({ commit, state, dispatch }) {
            try {
                // commit("SET_LOADING_API", true);
                // const polis = new Promise((resolve, reject) => {
                //     TujuanRujukanRepository.getTujuanRujukanByDokter(
                //         { size: 1000 },
                //         resolve,
                //         reject
                //     );
                // });
                // const responsePolis = await polis;

                // commit("SET_LIST_POLIS", responsePolis.data);
                // commit("SET_POLI", responsePolis.data[0]);
                // commit("SET_DOKTER", docAppoSession.dokter);

                // let docAppoSession = JSON.parse(
                //     sessionStorage.getItem("docAppo")
                // );
                // let scrollTime = `${moment().format("HH:mm:ss")}`;

                // if (
                    // docAppoSession &&
                    // docAppoSession.date &&
                    // (docAppoSession.poli || 
                        // docAppoSession.dokter
                        // )
                // ) {
                    // commit("SET_POLI", docAppoSession.poli);
                    // if (docAppoSession.poli) {
                    // }
                    // if (docAppoSession.dokter) {
                    //     commit("SET_DOKTER", docAppoSession.dokter);
                    // }
                    // scrollTime = `${docAppoSession.jamMulai}:00`;
                    // commit("SET_DATE", docAppoSession.date);
                    // setTimeout(() => {
                    //     dispatch("GO_TO_DATE", docAppoSession.date);
                    // }, 100);
                    // commit("SET_TYPE", docAppoSession.type);
                // } 
                // else if (state.poli == null && state.dokter == null) {
                //     console.log("masuk sini");
                //     commit("SET_POLI", responsePolis.data[0]);
                // }

                dispatch("SET_DOKTERS_BY_POLI");

                // commit("SET_LOADING_API", false);
                // setTimeout(() => {
                //     state.calendarApi.scrollToTime(scrollTime);
                // }, 1200);
            } catch (err) {
                toastr.error("error get data");
            }
        },
        async getDokterDropdownData({commit , state},payload){
           try {
                commit("SET_LIST_DOKTER" , []);
                if (Array.isArray(state.AbortControllerAndToken)) {
                    if (state.AbortControllerAndToken.length) {
                        state.AbortControllerAndToken.forEach(token => {
                            token.abort()
                        });
                        commit('SET_ABORT_TOKEN' , [])
                    }
                }
                const momentDay = moment(state.date);
                let param = {
                    "tanggalMulai.equals": momentDay
                        .clone()
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                    "tanggalSelesai.equals": momentDay
                        .clone()
                        .endOf("month")
                        .format("YYYY-MM-DD"),
                    sort: "asc",
                    page_size: 5
                };

                if (payload) {
                    const { query , spesialisasiIdAndSub} = payload
                    if (query) {
                        param.nama = query
                        param.page_size = 1000
                    }
                    if (spesialisasiIdAndSub) {
                        const {spesialisasiId,subSpesialisasiId} = spesialisasiIdAndSub
        
                        if (spesialisasiId) {
                            param.spesialisasi = spesialisasiId
                            param.page_size = 1000
                        }
                        if (subSpesialisasiId) {
                            param.subSpesialisasi = subSpesialisasiId
                            param.page_size = 1000
                        }
                    }
                }

                commit("SET_LOADING_API", true);

                const doctorRepo = DoctorRepository.getDokterDropdown(param)
                const abortController = await doctorRepo.next()

                commit('SET_ABORT_TOKEN' , [...state.AbortControllerAndToken,abortController.value])
                const reposi = await doctorRepo.next()

                commit("SET_LIST_DOKTER" , reposi.value);
                commit("SET_LOADING_API", false);
           } catch (error) {
                commit("SET_LOADING_API", false);
                console.log(error ,"error getDokterDropdownData");
           }
        },
        async getSpecialtiesDropdownData({commit, state} , query) {
            
            try {
                let params = {
                    sort: "asc",
                    page_size: 1000
                }

                if (query) {
                    params.nama = query
                    // params.page_size = 1000
                }
                // commit("SET_LOADING_API", true);
                const data =  await DoctorRepository.getSpecialties(params)
                commit("SET_SPECIALTIES", data);
                // commit("SET_LOADING_API", false);
            } catch (error) {
                // commit("SET_LOADING_API", false);
                console.log(error ,"error getSpecialtiesDropdownData");
            }
        },
        async SET_DOKTERS_BY_POLI({ dispatch, commit, state }) {
            var momentDay = moment(state.date);
            let param = {
                "tanggalMulai.equals": momentDay
                    .clone()
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                "tanggalSelesai.equals": momentDay
                    .clone()
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                sort: "nama"
            };
            if (state.poli) param = { tujuanRujukanId: state.poli.id };
            const commitCallback = (data) => {
                commit("SET_CONCAT_LIST_DOKTER" , data)
            }
            const dokter = new Promise((resolve, reject) => {
                // DoctorRepository.fetchDokterReservasi(param, resolve, reject);
                DoctorRepository.fetchAll(param, resolve, reject , commitCallback , false);
            });
            // if (loadingFunc) {
            //     loadingFunc(true)
            // }
            commit("SET_LOADING_API", true);
            const responseDokter = await dokter;
            commit("SET_LOADING_API", false);
            // if (loadingFunc) {
            //     loadingFunc(false)
            // }
            // commit("SET_LIST_DOKTER", responseDokter.data);
            
            // commit("SET_DOKTER", responseDokter.data[0]);
            // dispatch("RELOAD_CALENDAR");
        },
        SAVE_QUEUE_DEVICE({ commit, state, dispatch }, res) {
            if (res.id == null) {
                dispatch("POST_QUEUE_DEVICE", res);
            } else {
                dispatch("PUT_QUEUE_DEVICE", res);
            }
        },
        async POST_QUEUE_DEVICE({ commit, dispatch }, res) {
            try {
                const response = await QueueDeviceRepository.createQueueDevice(
                    res
                );
                commit("CallerStore/SET_QUEUE_DEVICE", response.data, {
                    root: true
                });
            } catch (err) {
                toastr.error(err);
            }
        },
        async PUT_QUEUE_DEVICE({ commit, dispatch }, res) {
            try {
                const response = await QueueDeviceRepository.updateQueueDevice(
                    res
                );
                commit("CallerStore/SET_QUEUE_DEVICE", response.data, {
                    root: true
                });
            } catch (err) {
                toastr.error(err);
            }
        },
        async GET_CURRENT_DOCTOR({ commit, state, dispatch }) {
                try {
                    const dokter = new Promise((resolve, reject) => {
                        DoctorRepository.getCurrentDoctors({}, resolve, reject);
                    });
                    const responseDokter = await Promise.all([dokter]);
                    commit("SET_LIST_DOKTER", responseDokter[0].data);
                    commit("SET_DOKTER", responseDokter[0].data[0]);
                    commit('SET_DOKTER_ARRAY', responseDokter[0].data)
                } catch (err) {
                    toastr.error("error get data");
                }
                dispatch('RELOAD_CALENDAR')
        }
    },
    getters: {
        GET_LIST_DOKTER_BY_POLI: state => {
            return state.poli
                ? state.listDokter.filter(x =>
                      x.tujuanRujukan
                          ? x.tujuanRujukan.id == state.poli.id
                          : false
                  )
                : state.listDokter;
        },
        GET_LIST_RESOURCE_DAY_CALENDAR: (state, getters) => {
            return state.dokter
                ? [state.dokter]
                : getters.GET_LIST_DOKTER_BY_POLI;
        },
        GET_SPESIALISASI_ID_AND_SUB: (state) => {
            if (state.selectedSpecialties) {
                const idObj = {}
                if (state.selectedSpecialties.type == "subspesialisasi") {
                    idObj.spesialisasiId = state.selectedSpecialties.parent_id
                    idObj.subSpesialisasiId = state.selectedSpecialties.id
                } else {
                    idObj.spesialisasiId = state.selectedSpecialties.id
                }
                return JSON.stringify(idObj) 
            }
            return null
        },
    }
};
