import axios from "axios";
import logoutHelpers from "@/services/logOut.service";

const baseDomain = process.env.VUE_APP_API_URL;
// const baseURL = `${baseDomain}/api`;
// const baseURL = `${baseDomain}/`;
const baseURL = `${baseDomain}/msloyalty/api`;


const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers["Authorization"] = "Bearer " + window.keycloak.token;
    return request;
});
instance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 403  || error.response.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        window.keycloak.updateToken(-1).success(() => {
            localStorage.setItem("tokens", window.keycloak.token);
            localStorage.setItem("refreshTokens", window.keycloak.refreshToken);
            return instance(originalRequest);
        }).error(() => {
            if(window.keycloak.token) {
                logoutHelpers.logout()
            } else {
                logoutHelpers.doLogout()
            }
        });
    } else {
        return Promise.reject(error);
    }
    
  });

export default instance;