import Vue from "vue";
import VueRouter from "vue-router";
import CheckPermission from "./../services/checkPermission.service";
import menu from "./menu";
Vue.use(VueRouter);
import {sourceAxios, sourceToken} from '../repositories/AllPaginateRepository'

const router = new VueRouter({
    mode: "history",
    linkExactActiveClass: "kt-menu__item--active",
    routes: [
        // 404
        // {
        //     path: "/error",
        //     name: "error",
        //     component: () =>
        //         import(/* webpackChunkName:"error" */
        //         "./../view/Error.vue")
        // },
        {
            path: "/link_dokter",
            name: "link",
            component: () =>
                import(/* webpackChunkName:"error" */
                "./../view/Link.vue")
        },
        {
            path: '/not-support',
            name: 'notSupport',
            component: () => 
                import('./../view/NotSupport.vue')
        },
        {
            path: "/login",
            name: "login",
            component: () =>
                import(/* webpackChunkName:"error" */
                "./../view/Login.vue")
        },
        // {
        //     path: "/logout",
        //     name: "logout",
        //     component: () =>
        //         import(/* webpackChunkName:"error" */
        //         "./../view/Logout.vue")
        // },
        {
            path: '/',
            component: () => import("../AppBomsu.vue"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () =>
                        import(/* webpackChunkName:"dashboard" */
                        "./../components/dashboards/Dashboard2.vue")
                },
                {
                    path: "/booking-list",
                    name: "call-center-appointment",
                    component: () =>
                        import(/* webpackChunkName:"dashboard" */
                        "./../components/call_center/CallCenter.vue")
                },
                // Dashboards
                // {
                //     path: '/dashboard2',
                //     name: 'dashboard2',
                //     component: () =>
                //         import(
                //             /* webpackChunkName:"dashboard" */ './../components/dashboards/Dashboard.vue'
                //         ),
                // },
                // Reservations
                // {
                //     path: '/reservasi',
                //     name: 'reservasi',
                //     component: () =>
                //         import(
                //             /* webpackChunkName:"reservation" */ './../components/reservations/Reservation.vue'
                //         ),
                // },
                // {
                //     path: '/histori-reservasi',
                //     name: 'histori-reservasi',
                //     component: () =>
                //         import(
                //             /* webpackChunkName:"reservation-history" */ './../components/reservations/ReservationHistory.vue'
                //         ),
                // },
                // {
                //     path: '/cancel-reservasi',
                //     name: 'cancel-reservasi',
                //     component: () =>
                //         import(
                //             /* webpackChunkName:"reservation-cancel" */ './../components/reservations/ReservationCancel.vue'
                //         ),
                // },
                // Medical Records
                {
                    path: "/rekam-medik",
                    name: "rekam-medik",
                    component: () =>
                        import(/* webpackChunkName:"medical-record" */
                        "./../components/medical_records/MedicalRecord.vue")
                },
                // Pharmacies
                {
                    path: "/farmasi",
                    name: "farmasi",
                    component: () =>
                        import(/* webpackChunkName:"pharmacy" */
                        "./../components/pharmacies/Pharmacy.vue")
                },
                // Queues
                {
                    path: "/antrian",
                    name: "antrian",
                    component: () =>
                        import(/* webpackChunkName:"queue" */
                        "./../components/queues/Queue.vue")
                },
                // Feedback
                {
                    path: "/tanggapan",
                    name: "tanggapan",
                    component: () =>
                        import(/* webpackChunkName:"feedback" */
                        "./../components/feedbacks/Feedback.vue")
                },
                // Doctors
                {
                    path: "/dokter",
                    name: "dokter",
                    component: () =>
                        import(/* webpackChunkName:"doctor" */
                        "./../components/doctors/Doctor.vue")
                },
                {
                    path: "/doctor",
                    name: "dokter-new",
                    component: () =>
                        import(/* webpackChunkName:"doctor" */
                        "./../components/doctors_new/DoctorsController.vue")
                },
                {
                    path: "/doctor-detail/:id",
                    name: "dokter-new-detail",
                    component: () =>
                        import(/* webpackChunkName:"doctor" */
                        "./../components/doctors_new/DoctorDetailController.vue")
                },
                // Absent Doctor
                {
                    path: "/absen-dokter",
                    name: "absen-dokter",
                    component: () =>
                        import(/* webpackChunkName:"absent-doctor" */
                        "./../components/doctors/AbsentDoctor.vue")
                },
                // PIC Pharmacy
                {
                    path: "/pic-farmasi",
                    name: "pic-farmasi",
                    component: () =>
                        import(/* webpackChunkName:"pic-pharmacy" */
                        "./../components/pic_pharmacies/PicPharmacy.vue")
                },
                // Patients
                {
                    path: "/pasien",
                    name: "pasien",
                    component: () =>
                        import(/* webpackChunkName:"patient" */
                        "./../components/patients/Patient.vue")
                },
                // Units
                {
                    path: "/unit",
                    name: "unit",
                    component: () =>
                        import(/* webpackChunkName:"unit" */
                        "./../components/units/Unit.vue")
                },
                // Queue Units
                {
                    path: "/unit-antrian",
                    name: "unit-antrian",
                    component: () =>
                        import(/* webpackChunkName:"queue-unit" */
                        "./../components/queue_units/QueueUnit.vue")
                },
                // Tujuan Rujukan
                {
                    path: "/tujuan-rujukan",
                    name: "tujuan-rujukan",
                    component: () =>
                        import(/* webpackChunkName:"tujuan-rujukan" */
                        "./../components/tujuan_rujukans/TujuanRujukan.vue")
                },
                // Jenis Penjamin
                {
                    path: "/jenis-penjamin",
                    name: "jenis-penjamin",
                    component: () =>
                        import(/* webpackChunkName:"jenis-penjamin" */
                        "./../components/jenis_penjamins/JenisPenjamin.vue")
                },
                // Tugas
                {
                    path: "/tugas",
                    name: "tugas",
                    component: () =>
                        import(/* webpackChunkName:"tugas" */
                        "./../components/tugas/Tugas.vue")
                },
                // Kategori
                {
                    path: "/kategori",
                    name: "kategori",
                    component: () =>
                        import(/* webpackChunkName:"kategori" */
                        "./../components/kategories/Kategori.vue")
                },
                // Kelas
                {
                    path: "/kelas",
                    name: "kelas",
                    component: () =>
                        import(/* webpackChunkName:"kelas" */
                        "./../components/kelas/Kelas.vue")
                },
                //Tindakan
                {
                    path: "/tindakan",
                    name: "tindakan",
                    component: () =>
                        import(/* webpackChunkName:"tindakan" */
                        "./../components/tindakans/Tindakan.vue")
                },
                // Gallery
                {
                    path: "/gallery",
                    name: "gallery",
                    component: () =>
                        import(/* webpackChunkName:"gallery" */
                        "./../components/galleries/Gallery.vue")
                },
                // Carousel
                {
                    path: "/carousel",
                    name: "carousel",
                    component: () =>
                        import(/* webpackChunkName:"carousel" */
                        "./../components/carousels/Carousel.vue")
                },
                {
                    path: "/user-group",
                    name: "user-group",
                    component: () =>
                        import(/* webpackChunkName:"user-group" */ "./../components/user_groups/UserGroup.vue")
                },
                // user-management
                {
                    path: "/user-management",
                    name: "user-management",
                    component: () =>
                        import(/* webpackChunkName:"user-management" */
                        "./../components/user_managements/UserManagement")
                },
                // entity Version
                {
                    path: "/entity-data-version",
                    name: "entity-data-version",
                    component: () =>
                        import(/* webpackChunkName:"user-management" */
                        "./../components/entity-data-version/EntityDataVersion")
                },
                {
                    path: "/entity-data-version/:id/:version",
                    name: "version-entity",
                    component: () =>
                        import(/* webpackChunkName:"user-management" */
                        "./../components/entity-data-version/VersionEntity")
                },
                // Report Daftar Perujuk
                {
                    path: "/report/daftar-perujuk",
                    name: "report-daftar-perujuk",
                    component: () =>
                        import(/* webpackChunkName:"report-status-fee-keuangan" */
                        "./../components/reports/daftar_perujuks/DaftarPerujuk")
                },
                // Report Status Rujukan
                {
                    path: "/report/status-rujukan",
                    name: "report-status-rujukan",
                    component: () =>
                        import(/* webpackChunkName:"report-status-rujukan" */
                        "./../components/reports/status_rujukans/StatusRujukan")
                },
                // Report Status fee keuangan
                {
                    path: "/report/status-referral-fee-keuangan",
                    name: "report-status-fee-keuangan",
                    component: () =>
                        import(/* webpackChunkName:"report-status-fee-keuangan" */
                        "./../components/reports/status_referral_fee_keuangans/StatusFeeKeuangan")
                },
                // Report Detail Transaksi
                {
                    path: "/report/detail-transaksi",
                    name: "report-detail-transaksi",
                    component: () =>
                        import(/* webpackChunkName:"report-detail-transaksi" */
                        "./../components/reports/detail_transaksis/DetailTransaksi")
                },
                // Report Pendapatan Referral
                {
                    path: "/report/pendapatan-referral",
                    name: "report-pendapatan-referral",
                    component: () =>
                        import(/* webpackChunkName:"report-pendapatan-referral" */
                        "./../components/reports/pendapatan_referrals/PendapatanReferral")
                },
                {
                    path: "/drive-thru-runner",
                    name: "drive-thru-runner",
                    component: () =>
                        import(/* webpackChunkName:"drive-thru-runner" */
                        "./../components/drive_thru/Runner_menu.vue")
                },
                {
                    path: "/drive-thru-booking",
                    name: "drive-thru-booking",
                    component: () =>
                        import(/* webpackChunkName:"drive-thru-booking" */
                        "./../components/drive_thru/KasirView.vue")
                },
                {
                    path: "/drive-thru-lab",
                    name: "drive-thru-lab",
                    component: () =>
                        import(/* webpackChunkName:"drive-thru-lab" */
                        "./../components/drive_thru/PetugasLab.vue")
                },
                {
                    path: "/drive-thru-swabber",
                    name: "drive-thru-swabber",
                    component: () =>
                        import(/* webpackChunkName:"drive-thru-swabber" */
                        "./../components/drive_thru/Swabber.vue")
                },
                {
                    path: "/drive-thru/view/:id",
                    name: "drive-thru-view",
                    component: () =>
                        import(/* webpackChunkName:"drive-thru-view" */
                        "./../components/drive_thru/BookingDetail.vue")
                },
                {
                    path: "/holiday-set",
                    name: "holiday-set",
                    component: () =>
                        import(/* webpackChunkName:"holiday-set" */
                        "./../components/holiday/holiday-page/HolidayController.vue")
                },
                {
                    path: "/ketidakhadiran",
                    name: "ketidakhadiran",
                    component: () =>
                        import(/* webpackChunkName:"ketidakhadiran" */
                        "./../components/ketidakhadiran/ketidakhadiran-page/KetidakhadiranController.vue")
                },
                {
                    path: "/klinik",
                    name: "klinik",
                    component: () =>
                        import(/* webpackChunkName:"holiday-set" */
                        "./../components/klinik/klinik-page/KlinikController.vue")
                },
                {
                    path: "/master-spesialisasi",
                    name: "master-spesialisasi",
                    component: () =>
                        import(/* webpackChunkName:"master-spesialisasi" */
                        "./../components/spesialisasi/spesialisasi-page/SpesialisasiController.vue")
                },
                {
                    path: "/master-subspesialisasi",
                    name: "master-subspesialisasi",
                    component: () =>
                        import(/* webpackChunkName:"master-subspesialisasi" */
                        "./../components/subspesialisasi/subspesialisasi-page/SubspesialisasiController.vue")
                },
                {
                    path: "/master-tarif",
                    name: "master-tarif",
                    component: () =>
                        import(/* webpackChunkName:"master-tarif" */
                        "./../components/tarif/tarif-page/TarifController.vue")
                },
                // Skema Rujukan
                {
                    path: "/skema-rujukan",
                    name: "skema-rujukan",
                    component: () =>
                        import(/* webpackChunkName:"skema-rujukan" */
                        "./../components/skema_rujukans/SkemaRujukan")
                },
                // Skema Rujukan add
                {
                    path: "/skema-rujukan/add",
                    name: "skema-rujukan-add",
                    component: () =>
                        import(/* webpackChunkName:"skema-rujukan-add" */
                        "./../components/skema_rujukans/SkemaRujukanForm")
                },
                // Skema Rujukan Edit
                {
                    path: "/skema-rujukan/edit/:id",
                    name: "skema-rujukan-edit",
                    component: () =>
                        import(/* webpackChunkName:"skema-rujukan-edit" */
                        "./../components/skema_rujukans/SkemaRujukanForm")
                },
                // Skema Rujukan view
                {
                    path: "/skema-rujukan/view/:id",
                    name: "skema-rujukan-view",
                    component: () =>
                        import(/* webpackChunkName:"skema-rujukan-view" */
                        "./../components/skema_rujukans/SkemaRujukanView")
                },
        
                {
                    path: "/change-password",
                    name: "change-password",
                    component: () =>
                        import(/* webpackChunkName:"change-password" */
                        "./../components/change_passwords/ChangePassword")
                },
                // app configuration
                {
                    path: "/application-configuration",
                    name: "app-config",
                    component: () =>
                        import(/* webpackChunkName:"app-config" */
                        "./../components/application_configurations/ApplicationConfiguration")
                },
                // Chats
                {
                    path: "/chats",
                    name: "chats",
                    component: () =>
                        import(/* webpackChunkName:"chats" */
                        "./../components/chats/Chat")
                },
                //Perujuk
                {
                    path: "/perujuk",
                    name: "perujuk",
                    component: () =>
                        import(/* webpackChunkName:"perujuk" */
                        "./../components/perujuks/Perujuk.vue")
                },
                //PIC
                {
                    path: "/pic",
                    name: "pic",
                    component: () =>
                        import(/* webpackChunkName:"pic" */
                        "./../components/Pics/Pic.vue")
                },
                //Transaksi Referral
                {
                    path: "/transaksi-referral",
                    name: "transaksi-referral",
                    component: () =>
                        import(/* webpackChunkName:"transaksi-referral" */
                        "./../components/transaksi_referrals/TransaksiReferralServerSide.vue")
                },
                {
                    path: "/transaksi-referral/add",
                    name: "transaksi-referral-add",
                    component: () =>
                        import(/* webpackChunkName:"transaksi-referral-add" */
                        "./../components/transaksi_referrals/TransaksiReferralForm.vue")
                },
                {
                    path: "/transaksi-referral/:id",
                    name: "transaksi-referral-edit",
                    component: () =>
                        import(/* webpackChunkName:"transaksi-referral-edit" */
                        "./../components/transaksi_referrals/TransaksiReferralForm.vue")
                },
                {
                    path: "/transaksi-referral-view/:id",
                    name: "transaksi-referral-view",
                    component: () =>
                        import(/* webpackChunkName:"transaksi-referral-view" */
                        "./../components/transaksi_referrals/TransaksiReferralView.vue")
                },
                //Elastic
                {
                    path: "/elastic",
                    name: "elastic",
                    component: () =>
                        import(/* webpackChunkName:"transaksi-referral" */
                        "./../components/elastics/Elastic.vue")
                },
                //ChatUser
                {
                    path: "/chat-user",
                    name: "chat-user",
                    component: () =>
                        import(/* webpackChunkName:"transaksi-referral" */
                        "./../components/chat_users/ChatUser.vue")
                },
                {
                    path: "/sms-template",
                    name: "sms-template",
                    component: () =>
                        import(/* webpackChunkName:"sms-template" */
                        "./../components/sms_templates/SmsTemplate")
                },
                {
                    path: "/sms-history",
                    name: "sms-history",
                    component: () =>
                        import(/* webpackChunkName:"sms-history" */
                        "./../components/sms_histories/SmsHistory")
                },
                {
                    path: '/email-history',
                    name: 'email-history',
                    component: () =>
                        import(
                            /* webpackChunkName:"sms-history" */
                            './../components/email_histories/EmailHistory'
                        ),
                },
                {
                    path: '/fcm-history',
                    name: 'fcm-history',
                    component: () =>
                        import(
                            /* webpackChunkName:"sms-history" */
                            './../components/fcm_histories/FCM_history'
                        ),
                },
                {
                    path: '/whatsapp-history',
                    name: 'whatsapp-history',
                    component: () =>
                        import(
                            /* webpackChunkName:"sms-history" */
                            './../components/whatsapp_histories/WhatsappHistory'
                        ),
                },
                //History Reservasi
                {
                    path: "/histori-reservasi-pasien/:id",
                    name: "histori-reservasi-pasien",
                    component: () =>
                        import(/* webpackChunkName:"reservation-history-pasien" */
                        "./../components/reservations/ReservationHistoryPasien.vue")
                },
                // Report Pendapatan Referral
                {
                    path: "/report/time-tracking-reservasi",
                    name: "report-time-tracking-reservasi",
                    component: () =>
                        import(/* webpackChunkName:"report-time-tracking-reservasi" */
                        "./../components/reports/time_tracking_reservations/TimeTrackingServer.vue")
                },
                // Members
                {
                    path: "/members",
                    name: "members",
                    component: () =>
                        import("./../loyalty/components/members/Member.vue")
                },
                // Kategori Promo
                {
                    path: "/kategori-promo",
                    name: "kategori-promo",
                    component: () =>
                        import("./../loyalty/components/kategori_promo/KategoriPromo.vue")
                },
                // Tier Loyalty
                {
                    path: "/loyalty-tier",
                    name: "loyalty-tier",
                    component: () =>
                        import("./../loyalty/components/loyalty_tier/LoyaltyTier.vue")
                },
                // Kupon
                {
                    path: "/coupons",
                    name: "coupons",
                    component: () =>
                        import("./../loyalty/components/coupons/Coupons.vue")
                },
                // Penggunaan Kupon
                {
                    path: "/coupon-usages",
                    name: "coupon-usages",
                    component: () =>
                        import("./../loyalty/components/coupon_usages/CouponUsages.vue")
                },
                // History Point
                {
                    path: "/history-point",
                    name: "history-point",
                    component: () =>
                        import("./../loyalty/components/history_point/HistoryPoint.vue")
                },
                {
                    path: "/claim-point",
                    name: "claim-point",
                    component: () =>
                        import("./../loyalty/components/claim_point/ClaimPoint.vue")
                },
                {
                    path: "/custom-point",
                    name: "custom-point",
                    component: () =>
                        import("./../loyalty/components/custom_point/CustomPoint.vue")
                },
                {
                    path: "/transaction-point-type",
                    name: "transaction-point-type",
                    component: () =>
                        import("./../loyalty/components/transaction_point_type/TransactionPointType.vue")
                },
                {
                    path: "/formula-loyalty",
                    name: "formula-loyalty",
                    component: () =>
                        import("./../loyalty/components/formula_loyalty/FormulaLoyalty.vue")
                },
                // Docktor Appoitment
                {
                    path: "/doctor-appointment",
                    name: "doctor-appointment",
                    component: () =>
                        import(/* webpackChunkName:"doctor-appointment" */
                        "./../components/doctor-appointments/DoctorAppointment.vue")
                },
                {
                    path: "/caller",
                    name: "caller",
                    component: () =>
                        import(/* webpackChunkName:"caller" */
                        "./../components/callers/Caller.vue")
                },
                {
                    path: "/room-management",
                    name: "room-management",
                    component: () =>
                        import(/* webpackChunkName:"caller" */
                        "./../components/room_management/RoomManagement.vue")
                },
                {
                    path: "/outpatient-dokter",
                    name: "outpatient-dokter",
                    component: () =>
                        import(/* webpackChunkName:"caller" */
                        "./../components/change_doctor/ChangeDoctor.vue")
                },
                {
                    path: "/tarif",
                    name: "tarif",
                    component: () =>
                        import(/* webpackChunkName:"tarif" */
                        "./../components/tarifs/Tarif.vue")
                },
                {
                    path: '/manajement-payment',
                    name: 'ManajementPayment',
                    component: () =>
                        import(
                            './../ecommerce/layout/Channels.vue'
                        )
                },
                {
                    path: '/manajement-notifikasi',
                    name: 'ManajementNotifikasi',
                    component: () =>
                        import(
                            './../ecommerce/layout/ManajemenNotif.vue'
                        )
                },
                {
                    path: '/manajement-notifikasi/:id',
                    name: 'EditManajementNotifikasi',
                    component: () =>
                        import(
                            './../ecommerce/layout/manajemen_notif/EditChannelNotif.vue'
                        )
                },
                {
                    path: '/manajement-notifikasi/:id/history',
                    name: 'HistoryManajementNotifikasi',
                    component: () =>
                        import(
                            './../ecommerce/layout/manajemen_notif/HistoryNotifikasi.vue'
                        )
                },
                {
                    path: '/payment-global',
                    name: 'payment-global',
                    component: () =>
                        import(
                            './../ecommerce/layout/PaymentGlobal.vue'
                        )
                },
                {
                    path: '/payment-global/add',
                    name: 'payment-global-add',
                    component: () =>
                        import(
                            './../ecommerce/layout/payment_global/EditPayment.vue'
                        ),
                },
                {
                    path: '/payment-global/:id',
                    name: 'payment-global-edit',
                    component: () =>
                        import(
                            './../ecommerce/layout/payment_global/EditPayment.vue'
                        ),
                },
                {
                    path: '/manajement-payment/:id',
                    name: 'manajement-payment-id',
                    component: () =>
                        import(
                            './../ecommerce/layout/channels/ListTransaksi.vue'
                        ),
                },
                {
                    path: '/manajement-payment/:id/webhook-history',
                    name: 'ShowWebhookHistory',
                    component: () =>
                        import(
                            './../ecommerce/components/config_payment/HistoryWebhook.vue'
                        ),
                },
                {
                    path: '/manajement-payment/:id/:payment',
                    name: 'ShowDetailPayment',
                    component: () =>
                        import(
                            './../ecommerce/components/DetailListTransaksi.vue'
                        ),
                },
                {
                    path: '/send-notif',
                    name: 'send-notifikasi',
                    component: () =>
                        import(
                            './../ecommerce/layout/SendNotif.vue'
                        ),
                },
                {
                    path: '/create-payment-snap/:id',
                    name: 'create-payment-snap',
                    component: () =>
                        import(
                            './../ecommerce/layout/CreatePaymentSnap.vue'
                        ),
                },
                {
                    path: "*",
                    name: "error",
                    component: () =>
                        import(/* webpackChunkName:"error" */
                        "./../components/errors/NotFoundComponent.vue")
                },
            ]
        },
        
        // Dashboards
        
    ]
});


router.beforeEach(async  (to, from, next) => {
    // next()
    // console.log('mo',to)
    if(sourceAxios) sourceAxios('cancel connection')
    if(sourceToken) sourceToken('cancel connection')
    if (to.name === 'link') {
        next();
    } else if (!window.keycloak.token && to.name !== 'login') {
        const uri = encodeURI(to.fullPath)
        next(`/login?redirect=${uri}`)
    } else if(window.keycloak.token && to.name == 'login') {
        next({name: 'dashboard'})
    } else if (menu.listMenuNonPermission().find(x => x.name == to.name) && to.name != 'login') {
        window.access_token = window.keycloak.token
        window.refresh_token = window.keycloak.refresh_token
        next();
    } else if (to.name != 'login') {
        window.access_token = window.keycloak.token
        window.refresh_token = window.keycloak.refresh_token
        const data =  await menu.listMenuPermission().find(x => x.name == to.name);
        const checkPermission = new CheckPermission();
        // console.log('dari router')
        await checkPermission.updatePermission()
            .then(response => {
                // console.log('errror', response)
                try{
                    if (data && checkPermission.isCanAccess(data.permission)) {
                        next();
                        const modal = $(".modal-backdrop");
                        if (modal) {
                            modal.hide();
                        }
                    } else {
                        next({
                            path: "404",
                            component:
                                "./../components/errors/NotFoundComponent.vue",
                            replace: true
                        });
                    }
                } catch {
                    console.error('error router')
                }
            })
            .catch(error => {
                console.log('error dari router')
                // next({name: 'login'})
                // return error;
            });
    }
    next()
});


export default router;
