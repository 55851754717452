import Vue from "vue";
import App from "./App.vue";
import ErrorVue from './Error.vue'
import axios from "axios";
import VueAxios from "vue-axios";
import VeeValidate, { Validator } from "vee-validate";
import UUID from "vue-uuid";
import Cleave from "vue-cleave-component";
import VueHtmlToPaper from "vue-html-to-paper";
import { ServerTable, ClientTable } from "vue-tables-2";
import JsonExcel from "vue-json-excel";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import helpers from "./services/helpers";
Vue.prototype.$eventBus = new Vue();
import store from "./store";
import vuei18n from "./i18n";
import CodeDiff from "v-code-diff";
import router from "./router/router";
import VueHtml2Canvas from 'vue-html2canvas';
import 'regenerator-runtime';
import { inspect } from "@xstate/inspect";
if(process.env.VUE_APP_INSPECT_MACHINE) {
    inspect({
        iframe: false,
      });
}
const printHtmlConfig = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        './assets/sass/style.bundle.css',
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
    ],
};
Vue.use(ServerTable, {}, false, "bootstrap4", "default");
Vue.use(ClientTable, {}, false, "bootstrap4", "default");
Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.use(UUID);
Vue.use(Cleave);
Vue.use(VueHtmlToPaper, printHtmlConfig);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CodeDiff);
Vue.use(VueHtml2Canvas);
Vue.component("downloadExcel", JsonExcel);
import "vue-multiselect/dist/vue-multiselect.min.css";

import id from "vee-validate/dist/locale/id";
// import '@fortawesome/fontawesome-free/css/all.min.css'
// import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
Validator.localize("id", id);
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

const i18n = new vuei18n();

Vue.i18n = i18n.internationalization();
App.i18n = Vue.i18n;
App.store = store;
App.router = router;
window.Vue = Vue;
window.helpers = helpers;

import Keycloak from "keycloak-js";
let initOptions = {
    url: process.env.VUE_APP_SSO_LINK,
    realm: process.env.VUE_APP_SSO_REALM,
    clientId: process.env.VUE_APP_SSO_CLIENT_ID,
};

window.keycloak = Keycloak(initOptions);

if (location.pathname === "/link") {
    window.keycloak
        .init({
            onload: "check-sso",
        })
        .then(
            (_) => {
                let params = (new URL(document.location)).searchParams;
                const idpHint = params.get('idpHint')
                window.keycloak.login({
                    idpHint,
                    redirectUri: `${window.location.origin}/link_dokter${window.location.search}`
                })                
            }
        );
} 
else {
    window.keycloak
        .init({
            onLoad: "check-sso",
            token: localStorage.tokens,
            refreshToken: localStorage.refreshTokens,
            silentCheckSsoRedirectUri:
                window.location.origin + "/silent-check-sso.html",
            messageReceiveTimeout: 60000
            // checkLoginIframe: false
        })
        .then((auth) => {
            axios
                .get(
                    `${process.env.VUE_APP_SSO_LINK}/realms/${process.env.VUE_APP_SSO_REALM}/caredokter-resources/idp-login`,
                    {
                        headers: {
                            authorization: `Bearer ${window.keycloak.token}`,
                        },
                    }
                )
                .then((_resp) => _resp.data)
                .then((_data) => {
                    localStorage.setItem("tenants", JSON.stringify(_data));
                });
            window.keycloak.onAuthLogout = () => {
                localStorage.removeItem('refreshTokens')
                localStorage.removeItem('tokens')
                window.location.reload();
            };
            window.keycloak.onAuthSuccess = () => {
                console.log("success");
            };
            window.keycloak.onReady = (auth) => {
                console.log("ready", auth);
            };
            window.keycloak.onTokenExpired = () => {
                if(localStorage.tokens) {
                    window.keycloak.token = localStorage.tokens
                    window.keycloak.refreshToken = localStorage.refreshTokens
               }
                window.keycloak
                    .updateToken(-1)
                    .success(() => {
                        localStorage.setItem("tokens", window.keycloak.token);
                        localStorage.setItem(
                            "refreshTokens",
                            window.keycloak.refreshToken
                        );
                        // window.access_token_keycloak = window.keycloak.token;
                        try {
                            const parseLob = JSON.parse(
                                atob(localStorage.tokens.split(".")[1])
                            );
                            window.tenantActive = parseLob.tenant_id;
                        } catch {

                        }
                    })
                    .error(() => {
                        localStorage.removeItem('refreshTokens')
                        localStorage.removeItem('tokens')
                        window.location.replace(window.location.origin + "/login");
                    });
            };
            if (auth) {
                window.keycloak.onTokenExpired();
                localStorage.setItem("tokens", window.keycloak.token);
                localStorage.setItem(
                    "refreshTokens",
                    window.keycloak.refreshToken
                );
                
                const parseLob = JSON.parse(
                    atob(localStorage.tokens.split(".")[1])
                );
                window.tenantActive = parseLob.tenant_id;
            } else {
                localStorage.removeItem('refreshTokens')
                localStorage.removeItem('tokens')
            }
            const loadingMonitor = Vue.observable({
                userName: ''
              })
              
              Object.defineProperty(window, 'userName', {
                get () {
                  return loadingMonitor.userName
                },
                
                set (value) {
                  loadingMonitor.userName = value
                }
              })
            new Vue({
                render: (h) => h(App, { props: { keycloak, auth } }),
            }).$mount("#app");
        }).catch(() => {
            new Vue({
                render: (h) => h(ErrorVue),
            }).$mount("#app");
        });
}
