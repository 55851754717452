import Repository from "./Repository";
import RepositoryAppointment from './RepositoryAppointment'

import createRequestOption from "./RequestParamUtil";
let sourceAxios = null;
let sourceToken = null
let cancelSource = null;
let cancelTokenSource = null
import axios from "axios";
const CancelToken = axios.CancelToken;

export default {
    async getAllPaginate(
        url,
        req,
        totalData = [],
        pages = 0,
        resolve,
        reject,
        cancelToken,
        commit,
        dokterParam
    ) {
        var parse = require("parse-link-header");
        if (req) {
            if (req.page) {
                req.page = pages;
            } else {
                req["page"] = pages;
            }
        } else {
            req = { page: pages };
        }
        let defaultConfigs = createRequestOption(req);
        if (!sourceAxios || cancelSource.token.reason) {
            cancelSource = CancelToken.source();
            sourceAxios = cancelSource.cancel;
            cancelSource.token.promise.then((_resp) => {
                if (_resp.message === "cancel connection") {
                    cancelToken.cancel();
                }
            });
        }

        if (cancelToken) {
            if (!sourceToken || cancelTokenSource.token.reason) {
                cancelTokenSource = cancelToken
                sourceToken = cancelTokenSource.cancel
                cancelTokenSource.token.promise.then((_resp) => {
                    if (_resp.message === "cancel connection") {
                        cancelToken.cancel();
                    }
                });
            }
            defaultConfigs.cancelToken = cancelToken.token;
        } else {
            defaultConfigs.cancelToken = cancelSource.token
        }
        if (dokterParam) {
            dokterParam.type;
            dokterParam.dokterIds.forEach((id) => {
                defaultConfigs.params.append(dokterParam.type, id);
            });
        }
        await Repository.get(url, defaultConfigs)
            .then((response) => {
                if (commit) {
                    commit(response.data);
                }
                const retriveData = totalData.concat(response.data);
                var parsed = parse(response.headers.link);
                if(parsed.next && defaultConfigs.cancelToken && !defaultConfigs.cancelToken.reason ){
                    this.getAllPaginate(
                        url,
                        req,
                        retriveData,
                        parsed.next.page,
                        resolve,
                        reject,
                        cancelToken,
                        commit,
                        dokterParam
                    );
                } else {
                    resolve(retriveData);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error.message);
                }
            });
    },
    async getAllPaginateAppointment(
        url,
        req,
        totalData = [],
        pages = 0,
        resolve,
        reject,
        cancelToken,
        commit,
        dokterParam
    ) {
        var parse = require("parse-link-header");
        if (req) {
            if (req.page) {
                req.page = pages;
            } else {
                req["page"] = pages;
            }
        } else {
            req = { page: pages };
        }
        let defaultConfigs = createRequestOption(req);
        if (!sourceAxios || cancelSource.token.reason) {
            cancelSource = CancelToken.source();
            sourceAxios = cancelSource.cancel;
            cancelSource.token.promise.then((_resp) => {
                if (_resp.message === "cancel connection") {
                    reject(_resp.message);
                    cancelToken.cancel();
                }
            });
        }

        if (cancelToken) {
            if (!sourceToken || cancelTokenSource.token.reason) {
                cancelTokenSource = cancelToken
                sourceToken = cancelTokenSource.cancel
                cancelTokenSource.token.promise.then((_resp) => {
                    if (_resp.message === "cancel connection") {
                        reject(_resp.message);
                        cancelToken.cancel();
                    }
                });
            }
            defaultConfigs.cancelToken = cancelToken.token;
        } else {
            defaultConfigs.cancelToken = cancelSource.token
        }
        if (dokterParam) {
            dokterParam.type;
            dokterParam.dokterIds.forEach((id) => {
                defaultConfigs.params.append(dokterParam.type, id);
            });
        }
        await RepositoryAppointment.get(url, defaultConfigs)
            .then((response) => {
                if (commit) {
                    commit(response.data);
                }
                const retriveData = totalData.concat(response.data);
                var parsed = parse(response.headers.link) ?? {};
                if(parsed.next && defaultConfigs.cancelToken && !defaultConfigs.cancelToken.reason ){
                    this.getAllPaginate(
                        url,
                        req,
                        retriveData,
                        parsed.next.page,
                        resolve,
                        reject,
                        cancelToken,
                        commit,
                        dokterParam
                    );
                } else {
                    resolve(retriveData);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error.message);
                }
            });
    },
    async getAllPaginateAppointmentWithCount(
        url,
        req,
        totalData = [],
        pages = 0,
        resolve,
        reject,
        cancelToken,
        commit,
        dokterParam,
        sizes = 5
    ) {
        const {page, size} = req
        req.page = page ?? pages
        req.size = size ?? sizes
        let defaultConfigs = createRequestOption(req);
        if (!sourceAxios || cancelSource.token.reason) {
            cancelSource = CancelToken.source();
            sourceAxios = cancelSource.cancel;
            cancelSource.token.promise.then((_resp) => {
                if (_resp.message === "cancel connection") {
                    reject(_resp.message);
                    cancelToken.cancel();
                }
            });
        }

        if (cancelToken) {
            if (!sourceToken || cancelTokenSource.token.reason) {
                cancelTokenSource = cancelToken
                sourceToken = cancelTokenSource.cancel
                cancelTokenSource.token.promise.then((_resp) => {
                    if (_resp.message === "cancel connection") {
                        reject(_resp.message);
                        cancelToken.cancel();
                    }
                });
            }
            defaultConfigs.cancelToken = cancelToken.token;
        } else {
            defaultConfigs.cancelToken = cancelSource.token
        }
        if (dokterParam) {
            dokterParam.type;
            dokterParam.dokterIds.forEach((id) => {
                defaultConfigs.params.append(dokterParam.type, id);
            });
        }
        await RepositoryAppointment.get(url, defaultConfigs)
            .then(response => response.data)
            .then((_data) => {
                if (commit) {
                    commit(_data.result);
                }
                const retriveData = totalData.concat(_data.result);
                const isNext = _data.count > ((req.page + 1) * req.size) 
                if(isNext && defaultConfigs.cancelToken && !defaultConfigs.cancelToken.reason ){
                    req.page += 1
                    this.getAllPaginateAppointmentWithCount(
                        url,
                        req,
                        retriveData,
                        null,
                        resolve,
                        reject,
                        cancelToken,
                        commit,
                        dokterParam,
                        null
                    );
                } else {
                    resolve(retriveData);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error.message);
                }
            });
    },
    async getAllPaginateWithConfigOld(
        url,
        req,
        totalData = [],
        pages = 0,
        resolve,
        reject
    ) {
        var parse = require("parse-link-header");
        if (req.get("page")) {
            req.set("page", pages);
        } else {
            req.append("page", pages);
        }
        await Repository.get(url, { params: req })
            .then((response) => {
                const retriveData = totalData.concat(response.data);
                var parsed = parse(response.headers.link);
                if (parsed.next) {
                    this.getAllPaginateWithConfigOld(
                        url,
                        req,
                        retriveData,
                        parsed.next.page,
                        resolve,
                        reject
                    );
                } else {
                    resolve(retriveData);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error.message);
                }
            });
    },
    async getAllPaginateWithQueryParams(
        url,
        req,
        totalData = [],
        pages = 0,
        resolve,
        reject
    ) {
        var parse = require("parse-link-header");
        await RepositoryAppointment.get(`${url}?${req}`)
            .then((response) => {
                const retriveData = totalData.concat(response.data);
                var parsed = parse(response.headers.link) ?? {};
                if (parsed.next) {
                    this.getAllPaginateWithQueryParams(
                        url,
                        req,
                        retriveData,
                        parsed.next.page,
                        resolve,
                        reject
                    );
                } else {
                    resolve(retriveData);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error.message);
                }
            });
    },
    async getAllPaginateWithConfig(
        url,
        req,
        totalData = [],
        pages = 1,
        resolve,
        reject,
        cancelToken,
        baseConfig
    ) {
        // var parse = require('parse-link-header');
        if (req) {
            if (req.page) {
                req.page = pages;
            } else {
                req["page"] = pages;
            }
        } else {
            req = { page: pages };
        }
        let defaultConfigs = createRequestOption(req);
        if (cancelToken) {
            defaultConfigs.cancelToken = cancelToken.token;
        }
        await Repository.get(url, { ...baseConfig, ...defaultConfigs })
            .then((response) => {
                const retriveData = [...totalData, ...response.data];
                // var parsed = parse(response.headers.link);
                const totalCount = retriveData.length;
                if (response.headers["x-count"] > totalCount) {
                    this.getAllPaginateWithConfig(
                        url,
                        req,
                        retriveData,
                        pages + 1,
                        resolve,
                        reject,
                        cancelToken,
                        baseConfig
                    );
                } else {
                    resolve(retriveData);
                }
            })
            .catch((error) => {
                if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error.message);
                }
            });
    },
};
export {sourceAxios, sourceToken}