import axios from "axios";
import logoutHelpers from "../../services/logOut.service";

const baseDomain = process.env.VUE_APP_API_URL
// const baseDomain = `http://192.168.255.18:8082`

const baseURL = `${baseDomain}`;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(request => {
    request.headers["Authorization"] = "Bearer " + localStorage.tokens;
    request.headers["X-TenantId"] = window.tenantActive;
    request.headers.common["Access-Control-Allow-Origin"] = "*";

    return request;
});
instance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 403  || error.response.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        window.keycloak.updateToken(-1).success(() => {
            localStorage.setItem("tokens", window.keycloak.token);
            localStorage.setItem("refreshTokens", window.keycloak.refreshToken);
            return instance(originalRequest);
        }).error(() => {
            if(localStorage.tokens) {
                logoutHelpers.logout()
            } else {
                logoutHelpers.doLogout()
            }
        });
    } else {
        return Promise.reject(error);
    }
  });

export default instance;
