<template>
    <div class="row mb-2">
        <div class="col-md-6">
            <div class="row">
                <div class="mt-2 col-md-2">
                    <button
                        class="btn btn-default btn-bold btn-font-md"
                        type="button"
                        @click="changeDate('today')"
                    >
                        {{ selectedType == 2 ? "Hari ini" : "Bulan ini" }}
                    </button>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="btn-group" role="group">
                        <button
                            class="btn btn-clean btn-bold  btn-font-md"
                            type="button"
                            @click="changeDate('prev')"
                        >
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <div class="btn-group" role="group">
                            <button
                                class="btn btn-clean btn-bold btn-font-md"
                                id="dropdown-datepicker"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{ date | filterDate(type) }}
                            </button>
                            <div class="dropdown-menu dropdown-menu1 p-3">
                                <vue-date-picker
                                    :key="componentKey"
                                    v-model="selectedDate"
                                    :inline="true"
                                    :type="typePicker"
                                    @change="closeDropdown"
                                >
                                </vue-date-picker>
                            </div>
                        </div>
                        <button
                            class="btn btn-clean btn-bold btn-font-md"
                            type="button"
                            @click="changeDate('next')"
                        >
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row mt-3">
                        <!-- <span class="m-1">Auto Refresh </span> -->
                        <auto-refresh
                            ref="auth"
                            :on-refresh="reloadCalendar"
                            :auto-refresh-state="false"
                            :is-visible="true"
                        ></auto-refresh>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <!-- <div class="row flex-row-reverse">
                <div class="col-md-auto mt-2">
                    <form @change="changeType">
                        <div class="btn-group btn-group-toggle">
                                <label class="btn btn-default" :class="selectedType == 1 ? 'active' : ''">
                                    <input type="radio" v-model="selectedType" :value="1">
                                    <i class="fas fa-calendar-alt"></i>
                                </label>
                                <label class="btn btn-default" :class="selectedType == 2 ? 'active' : ''">
                                    <input type="radio" v-model="selectedType" :value="2">
                                    <i class="fas fa-list"></i>
                                </label>
                        </div>
                    </form>
                </div>
                <div class="col-md-2 mt-2">
                    <select class="form-control" v-model="selectedType" @change="changeType">
                        <option :value="1">Bulan</option>
                        <option :value="2">Hari</option>
                    </select>
                </div>
                <div class="col-md-4 mt-2">
                    <doktor
                        v-model="selectedDoctor"
                        :options="listDokter"
                        :loading="loading"
                        :placeholder="'Semua Doktor'"
                    ></doktor>
                </div>
                <div class="col-md-4 mt-2">
                    <poli
                        v-model="selectedKlinik"
                        :placeholder="'Pilih Klinik'"
                        :options="listPolis"
                        :loading="loading"
                    >
                    </poli>
                </div>
            </div> -->

            <div class="row d-flex justify-content-end">
                <div class="col-md-4 mt-2" v-if="!doctorLogin">
                    <!-- <poli
                        v-model="selectedKlinik"
                        :placeholder="'Pilih Klinik'"
                        :options="listPolis"
                        :loading="loading"
                        ref="poli-appo"
                    >
                    </poli> -->
                    <Multiselect
                        :options="specialties"
                        track-by="id"
                        label="nama"
                        v-model="selectedSpesialisasi"
                        :custom-label="customSpeLabel"
                        placeholder="Specialties"
                        :loading="loadingSpecialties"
                    >   
                        <!-- @search-change="searchSpecialties" -->
                        <div v-if="loadingSpecialties" slot="beforeList" class="beforeList">
                            <p style="text-align:center;margin-top:10px;">
                                Sedang dalam pencarian
                            </p>
                        </div>
                        <div v-else slot="beforeList" class="beforeList">
                            <p style="text-align:center;margin-top:10px;">
                                Ketik Untuk Mencari Lebih Lanjut
                            </p>
                        </div>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">
                                   <strong> {{ props.option.spName }}</strong> 
                                </span>
                                <span class="option__small">{{ props.option.sbName }}</span>
                            </div>
                        </template>
                    </Multiselect>
                    
                </div>

                <div class="col-md-4 mt-2" v-if="!doctorLogin">
                    <doktor
                        v-model="selectedDoctor"
                        :options="listDokter"
                        :loading="loading || loadingDokter"
                        :is-show-tujuan="true"
                        :placeholder="'Dokter'"
                        ref="dokter-appo"
                        :multiple="true"
                        :isNotShowSelectedTags="true"
                        :isShowSearchNote="true"
                        :searchChange="searchDokter"
                        :isCloseOnSelect="false"
                        :isClearOnSelect="false"
                    ></doktor>
                </div>
                <button @click.prevent="reload" class="btn btn-brand btn-md btn-bold mt-2" v-if="!doctorLogin">
                    Search
                </button>
                <div :class="doctorLoginClass">
                    <select
                        class="form-control"
                        v-model="selectedType"
                        @change="changeType"
                    >
                        <option :value="1">Bulan</option>
                        <option :value="2">Hari</option>
                    </select>
                </div>
            </div>
        </div>
        <div
            class="col-md-auto mt-2 d-flex "
            style="width:100%"
            :class="doctorLogin ? 'flex-row-reverse': 'justify-content-between'"
        >
            <!-- <div
            class="selected-filter"
            style="margin-top: 5px;width: 75%;"
            > -->
                <FilterItems
                v-show="!doctorLogin"
                style="height:37px;width:75%;"
                :indvFilterHeight="25"
                :filterList="selectedDokterMapped"
                :clickedMethod="deleteIndvFIlter"
                >
                </FilterItems>
            <!-- <a style="cursor: pointer;">Hapus Filter</a>
            </div> -->
            <form @change="changeType">
                <div class="btn-group btn-group-toggle">
                    <label
                        class="btn btn-default"
                        :class="selectedType == 1 ? 'active' : ''"
                    >
                        <input type="radio" v-model="selectedType" :value="1" />
                        <i class="fas fa-calendar-alt"></i>
                    </label>
                    <label
                        class="btn btn-default"
                        :class="selectedType == 2 ? 'active' : ''"
                    >
                        <input type="radio" v-model="selectedType" :value="2" />
                        <i class="fas fa-list"></i>
                    </label>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Poli from "./../../_select/Poli.vue";
import Doktor from "./../../_select/Doktor.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import VueDatePicker from "./../../_general/VueDatePicker.vue";
import AutoRefresh from "./../../_general/AutoRefresh";

import Multiselect from 'vue-multiselect'

import FilterItems from "../../_base/FilterItems.vue";
export default {
    components: {
        Poli,
        Doktor,
        VueDatePicker,
        AutoRefresh,
        FilterItems,
        Multiselect
    },
    data() {
        let loadingDokter = (val) => { this.loadingDokter = val }
        // let loadingSpecialties = (val) => this.loadingSpecialties = val
        let selectedSpesialisasi = () => this.selectedSpesialisasi
        return {
            componentKey: 1,
            selectedMode: 1,
            loadingDokter: false,
            loadingSpecialties: false,
            searchDokter: _.debounce(async query => {
                try {
                    if (selectedSpesialisasi()) {
                        return
                    }
                    loadingDokter(true)
                    if (query) {
                        await this.getDokterDropdownData({query ,spesialisasiIdAndSub: JSON.parse(this.spesialisasiIdAndSub) })
                    } else {
                        await this.getDokterDropdownData({spesialisasiIdAndSub: JSON.parse(this.spesialisasiIdAndSub) })
                    }
                    loadingDokter(false)
                } catch (error) {
                    loadingDokter(false)
                    console.log(error , "error searchDokter");
                }
            }, 500),
            // searchSpecialties: _.debounce(async query => {
            //     try {
            //         loadingSpecialties(true)
            //         if (query) {
            //             await this.getSpecialtiesDropdownData(query)
            //         } else {
            //             await this.getSpecialtiesDropdownData()
            //         }
            //         loadingSpecialties(false)
            //     } catch (error) {
            //         loadingSpecialties(false)
            //         console.log(error , "error searchDokter");
            //     }
            // }, 500),
            debounceDoctor: _.debounce(() => {
                this.reload()
            }, 1000),
            selectedSpesialisasi: null,
            spesialtiesOptions: [],
        };
    },
    watch: {
        spesialisasiIdAndSub: {
            handler(val, oldVal) {
                // console.log('spesialisasiIdAndSub' , val , oldVal , val == oldVal);
                if (val && (val != oldVal)) {
                    
                    try {
                        this.getDokterDropdownData({spesialisasiIdAndSub: JSON.parse(val)})
                    } catch (error) {
                        console.log(error ,'spesialisasiIdAndSub watch handler' );
                    }
                } else if((val && !oldVal) || (!val && oldVal)) {
                    this.getDokterDropdownData()
                }
                // this.getDokterDropdownData({query: null ,spesialisasiIdAndSub: val})
            },
            deep: true
        },
        selectedSpesialisasi: {
            handler(val, oldVal) {
                // this.getDokterDropdownData({query: null ,spesialisasiIdAndSub: this.spesialisasiIdAndSub})
                // this.spesialisasiIdAndSub = val
                this.SetSelectedSpecialties(val)
            },
            deep: true
        },
    },
    computed: {
        ...mapState("DoctorAppointmentStore", {
            date: state => state.date,
            type: state => state.type,
            calendarApi: state => state.calendarApi,
            listPolis: state => state.listPolis,
            loading: state => state.loadingApi,
            dokter: state => state.dokter,
            dokterArray: state => state.dokterArray,
            specialties: state => state.specialties,
            poli: state => state.poli,
            doctorLogin: state => state.doctorLogin,
            selectedSpecialties: state => state.selectedSpecialties
        }),
        ...mapState("CallerStore", {
            queueDevice: state => state.queueDevice,
            userNotification: state => state.userNotification
        }),
        ...mapGetters({
            listDokter: "DoctorAppointmentStore/GET_LIST_DOKTER_BY_POLI",
            spesialisasiIdAndSub: "DoctorAppointmentStore/GET_SPESIALISASI_ID_AND_SUB"
        }),
        typePicker() {
            return this.type == 1 ? "month" : "date";
        },
        selectedDate: {
            get() {
                return this.date;
            },
            set(newValue) {
                this.goToDate(newValue);
                this.reloadCalendar();
            }
        },
        selectedType: {
            get() {
                return this.type;
            },
            set(newValue) {
                this.settingType(newValue);
                this.componentKey++;
            }
        },
        selectedDoctor: {
            get() {
                return this.dokterArray;
            },
            set(newValue) {
                this.setDokterArray(newValue);
            }
        },
        selectedDokterMapped: {
            get() {
                return this.dokterArray.map(dokter => {
                    return {
                        value: dokter.nama,
                        category: dokter.id
                    };
                });
            }
        },
        doctorLoginClass() {
            if (this.doctorLogin) {
                return "col-md-3 mt-2";
            } else {
                return "col-md-2 mt-2";
            }
        }
    },
    filters: {
        filterDate: function(value, type) {
            var momDate = moment(value);
            return type == 1
                ? momDate.format("MMMM YYYY")
                : momDate.format("DD MMMM YYYY");
        }
    },
    methods: {
        ...mapMutations({
            setDate: "DoctorAppointmentStore/SET_DATE",
            setDokter: "DoctorAppointmentStore/SET_DOKTER",
            setDokterArray: "DoctorAppointmentStore/SET_DOKTER_ARRAY",
            setPoli: "DoctorAppointmentStore/SET_POLI",
            SetType: "DoctorAppointmentStore/SET_TYPE",
            SetSelectedSpecialties: "DoctorAppointmentStore/SET_SELECTED_SPECIALTIES"
        }),
        ...mapActions({
            today: "DoctorAppointmentStore/TODAY",
            prev: "DoctorAppointmentStore/PREV",
            next: "DoctorAppointmentStore/NEXT",
            goToDate: "DoctorAppointmentStore/GO_TO_DATE",
            changeType: "DoctorAppointmentStore/CHANGE_TYPE",
            reloadCalendar: "DoctorAppointmentStore/RELOAD_CALENDAR",
            setDokterByPoli: "DoctorAppointmentStore/SET_DOKTERS_BY_POLI",
            settingType: "DoctorAppointmentStore/SETTING_TYPE",
            saveQueueDevice: "DoctorAppointmentStore/SAVE_QUEUE_DEVICE",
            getDokterDropdownData: "DoctorAppointmentStore/getDokterDropdownData",
            getSpecialtiesDropdownData: "DoctorAppointmentStore/getSpecialtiesDropdownData",
        }),
        customSpeLabel(spesialis) {
            let spesialisString = "-"
            if (spesialis.nama) {
                spesialisString = spesialis.nama
            } 
            return spesialisString
        },
        reload() {
            if (!this.selectedDoctor.length) {
                toastr.error("Mohon pilih dokter terlebih dahulu");
                this.$refs['dokter-appo'].$children[0].$children[0].activate() 
            } else {
                const dokterId = this.selectedDoctor.map(_item => _item.id).join(',')
                // this.$router.replace({name: 'doctor-appointment', query: {...this.$route.query, dokterId}})
            }
            this.reloadCalendar();
            // this.settingQueueDevice();
        
        },
        deleteIndvFIlter(payload) {
            let { value } = payload;
            const asArray = Object.entries(this.selectedDokterMapped);

            const dokterIds = {};
 
            asArray.forEach(el => {
                dokterIds[el[1].category] = el[1].value;
            }); 
            this.selectedDoctor = this.dokterArray.filter(dokter => {
                if (dokterIds[dokter.id]) {
                    return value != dokterIds[dokter.id];
                } 
            });
            // this.debounceDoctor()
        },
        setSessionDocAppo() {
            let obj = {
                date: this.date,
                dokter: this.dokter,
                type: this.type,
                poli: this.poli
            };
            sessionStorage.setItem("docAppo", JSON.stringify(obj));
        },
        changeDate(param) {
            if (param == "today") this.today();
            else if (param == "prev") this.prev();
            else if (param == "next") this.next();
            this.reloadCalendar();
        },
        closeDropdown() {
            $(".dropdown-menu").slideUp();
        },
        settingQueueDevice() {
            setTimeout(() => {
                const device = { ...this.queueDevice };
                device.queueUnits = [];
                device.dokters =
                    this.dokter != null
                        ? [
                              {
                                  id: `${this.dokter.id}`
                              }
                          ]
                        : [];
                device.notificationId = this.userNotification.notificationId;
                this.saveQueueDevice(device);
            }, 10);
        },
        getPositionWidth(elchild, parent) {
            const rectChild = elchild ? elchild.$el.getBoundingClientRect() : null;
            const rectParent = parent.getBoundingClientRect();
            const width = rectParent.right - rectChild.left;
            return width;
        },
        setMaxWidth(el) {
            const width = this.getPositionWidth(
                this.$refs[el],
                document.getElementById("kt_content")
            );
            const element = this.$refs[el].$el;
            const wrapper = element.querySelector(
                ".multiselect__content-wrapper"
            );
            if (wrapper) {
                wrapper.style.maxWidth = `${width}px`;
            }
        }
    },
    mounted() {
        // this.setMaxWidth('poli-appo')
        this.setMaxWidth("dokter-appo");
        $("#dropdown-datepicker").on("click", function(event) {
            $(".dropdown-menu1").slideToggle();
            event.stopPropagation();
        });
        $(".dropdown-menu1").on("click", function(event) {
            event.stopPropagation();
        });
        $(window).on("click", function() {
            $(".dropdown-menu1").slideUp();
        });
        $(window).resize(() => {
            // this.setMaxWidth('poli-appo')
            this.setMaxWidth("dokter-appo");
        });
        this.$eventBus.$on("toggle-click", () => {
            setTimeout(() => {
                // this.setMaxWidth('poli-appo')
                this.setMaxWidth("dokter-appo");
            }, 500);
        });
    },
    beforeDestroy() {
        this.setSessionDocAppo();
        $(window).off("resize");
    }
};
</script>

<style lang="scss" scoped></style>
